import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import ProtocolloContext from './ProtocolloContext';
import Layout from './components/Layout';
import LayoutAnonymous from './components/LayoutAnonymous';
import Home from './components/Home';
import Inbound from './components/Inbound';
import Outbound from './components/Outbound';
import Loading from './components/Loading';
import { getToken, verifyUsersRoles, convertRoleToContext, roleIsReadOnly } from './adalConfig';
import jwt_decode from "jwt-decode";
import 'office-ui-fabric-react/dist/css/fabric.min.css';
import './custom.css'


const App = (props) => {

    const [loadingContext, setLoadingContext] = useState(false);
    const [userHasToChooseRole, setUserHasToChooseRole] = useState(true);
    const [protocolloState, setProtocolloState] = useState({});
    const chooseContext = async (roleName) => {
        setLoadingContext(true)
        await convertRoleToContext(roleName).then(response => {
            window.sessionStorage.setItem("currentRole", roleName);
            window.sessionStorage.setItem('ProtocolloDBContext', response);
            window.sessionStorage.setItem("roleIsReadOnly", roleIsReadOnly(roleName))
            setProtocolloState({ ...protocolloState, context: response, currentRole: roleName, roleIsReadOnly: roleIsReadOnly(roleName) });
            setUserHasToChooseRole(false);
            setLoadingContext(false)
        })
    }

    useEffect(() => {
        async function setContext() {
            if (window.sessionStorage.getItem('ProtocolloDBContext') && (
                window.sessionStorage.getItem('ProtocolloDBContext') === 'SA' ||
                window.sessionStorage.getItem('ProtocolloDBContext') === 'IT'
            )) {
                setUserHasToChooseRole(false);
                setProtocolloState({
                    ...protocolloState,
                    context: window.sessionStorage.getItem('ProtocolloDBContext'),
                    currentRole: window.sessionStorage.getItem("currentRole"),
                    roleIsReadOnly: window.sessionStorage.getItem("roleIsReadOnly") === 'true'
                });
            }
            else {
                setLoadingContext(true);
                setProtocolloState({ ...protocolloState, allowedUserRoles: [], currentRole: null, roleIsReadOnly: true });
                var decodedToken = jwt_decode(getToken());
                await verifyUsersRoles(decodedToken).then((response) => {
                    if (response.status === 'success') {
                        if (response.allowedUserRoles !== null && response.allowedUserRoles !== undefined) {
                            (response.allowedUserRoles.length == 1) ?
                                chooseContext(response.allowedUserRoles[0])
                                :
                                setProtocolloState({ ...protocolloState, allowedUserRoles: response.allowedUserRoles, currentRole: null, roleIsReadOnly: true })
                        }
                    }
                    setLoadingContext(false);
                });
                window.sessionStorage.setItem("UserFullName", decodedToken.name);
            }
        }
        setContext();
    }, []);

    return (!loadingContext) ?
        (userHasToChooseRole) ?
            (protocolloState.allowedUserRoles !== undefined && protocolloState.allowedUserRoles !== null) ?
                <LayoutAnonymous>
                    <div className="mt-5">
                        <h5 className="ms-font-su ms-fontColor-themePrimary">Benvenuto sul Protocollo</h5>
                        <h6>{window.sessionStorage.getItem("UserFullName")} per questo applicativo devi avere un ruolo.</h6>
                        {
                            protocolloState.allowedUserRoles.map((item, index) => {
                                var title = (index == 0) ? 'Scegli un ruolo tra quelli disponibili:' : null;
                                return (
                                    <React.Fragment key={index}>
                                        <h6 className="mb-3 ms-fontColor-themePrimary">{title}</h6>
                                        <div className="ml-3 mt-1">
                                            <button className="btn btn-light" onClick={() => chooseContext(item)}>{item}</button>
                                        </div>
                                    </React.Fragment>);
                            })
                        }
                    </div>
                </LayoutAnonymous>
                :
                <Loading message="Caricamento ruoli utente in corso..." />
            :
            (protocolloState.context !== null && protocolloState.context !== undefined) ?
                <ProtocolloContext.Provider value={[protocolloState, setProtocolloState]}>
                    <Layout>
                        {(!protocolloState.roleIsReadOnly) ?
                            <Route exact path='/' render={() => <Home {...props} />} />
                            :
                            <Route exact path='/' render={() => <Inbound {...props} />} />
                        }
                        {(!protocolloState.roleIsReadOnly) ?
                            <Route path='/outbound' render={() => <Outbound {...props} />} />
                            :
                            null
                        }
                        <Route path='/inbound' render={() => <Inbound {...props} />} />
                    </Layout>
                </ProtocolloContext.Provider >
                :
                <Loading message="Caricamento contesto protocollo in corso..." />
        :
        <Loading message="Verifica utente in corso..." />
}
export default App;
