import React, { useState, useContext } from 'react';
import ProtocolloContext from '../ProtocolloContext';
import { adalApiFetch, requestOptions } from '../adalConfig';
import moment from 'moment';
import 'moment-timezone';
import { DatePicker, Dropdown, TextField, DefaultButton, ComboBox } from 'office-ui-fabric-react';

const FormInbound = (props) => {
    const [protocolloState, setProtocolloState] = useContext(ProtocolloContext);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        Context: protocolloState.context,
        DataArrivo: "",
        DataInvio: "",
        Mittente: "",
        Area: "",
        Mezzo: "",
        Ricezione: "",
        Documenti: [],
        Note: "",
    });
    const [showAreaError, setShowAreaError] = useState(false);
    const [showMezzoError, setShowMezzoError] = useState(false);
    const [initialDataArrivo, setInitialDataArrivo] = useState("");
    const [initialDataInvio, setInitialDataInvio] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [showDataArrivoError, setShowDataArrivoError] = useState(false);
    const [idProtocolloCreated, setIdProtocolloCreated] = useState(null);    
    const [selectedArea, setSelectedArea] = useState("");
    const [selectedMezzo, setSelectedMezzo] = useState("");
    const [selectedRicezione, setSelectedRicezione] = useState("");
    const [selectedDocumentKeys, setSelectedDocumentKeys] = useState([]);
    
    const updateFormData = (field, value) => {
        var newData = { ...formData };
        if (field === "Area")
            setShowAreaError(false);
        if (field === "Mezzo")
            setShowMezzoError(false);
        if (field === "DataArrivo" && value !== "" && value !== null) {
            setShowDataArrivoError(false);
            setInitialDataArrivo(value);
            newData[field] = moment(value).format("YYYY-MM-DD");
        }
        else if (field === "DataInvio" && value !== "" && value !== null) {
            setInitialDataInvio(value);
            newData[field] = moment(value).format("YYYY-MM-DD");
        }
        else if (field === "Documenti") {
            newData.Documenti = [...value];
            //newData.Documenti.pushIfNotExist(value.key, function (e) {
            //    return e.key === value.key && e.text === value.text;
            //});
        }
        else {
            newData[field] = value;
        }
        setFormData(newData);
    }
    const registerInbound = async (event) => {        
        setIsLoading(true);
        event.preventDefault();
        var blockSend = false;
        if (formData.Context === "" || formData.Context === undefined) {
            formData.Context = protocolloState.context;
        }
        if (formData.DataArrivo === "") {
            setShowDataArrivoError(true);
            blockSend = (!blockSend) ? true : blockSend;
        }
        if (formData.Area === "") {
            setShowAreaError(true);
            blockSend = (!blockSend) ? true : blockSend;
        }
        if (formData.Mezzo === "") {
            setShowMezzoError(true);
            blockSend = (!blockSend) ? true : blockSend;
        }
        if (blockSend) {
            setIsLoading(false);
            return false;
        }
        else {
            setIsLoading(true);
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/protocolloinbound`;
            var optionsPost = requestOptions;
            optionsPost.method = 'post';
            optionsPost.body = JSON.stringify(formData);
            await adalApiFetch(fetch, url, optionsPost)
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        if (data.idProtocollo > 0) {
                            setIdProtocolloCreated(data.idProtocollo);
                            setShowConfirm(true);
                            setFormData({
                                Context: protocolloState.context,
                                DataArrivo: "",
                                DataInvio: "",
                                Mittente: "",
                                Area: "",
                                Mezzo: "",
                                Ricezione: "",
                                Documenti: [],
                                Note: "",
                            });
                            setInitialDataArrivo("");
                            setInitialDataInvio("");
                        }
                        else {
                            alert("Si è verificato un errore, protocollo non inserito.")
                        }
                    }
                    else {
                        alert("Si è verificato un errore, risposta del servizio non valida.")
                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    alert("Si è verificato un errore di comunicazione con il servizio.")
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }
    const backToForm = () => {
        setInitialDataArrivo("");
        setInitialDataInvio("");
        setShowConfirm(false);
    }

    if (showConfirm) {
        return (
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-9 text-center pt-4">
                    <div className="ms-font-xxl">Protocollo creato <span className="ms-fontColor-themePrimary">#{idProtocolloCreated}</span>.</div>
                    <DefaultButton className="mt-4" text="Indietro" onClick={() => backToForm()} />
                </div>
            </div>
        );
    }
    else {
        return (
            <form id="inboundForm" method="POST" onSubmit={registerInbound}>
                <div className="row mb-3">
                    <div className="col-12 ms-font-l ms-fontColor-themePrimary">
                        Informazioni per il Protocollo
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <DatePicker
                            id="DataArrivoInbound"
                            value={initialDataArrivo}
                            strings={props.dayPickerStrings}
                            firstWeekOfYear={1}
                            firstDayOfWeek={1}
                            showMonthPickerAsOverlay={true}
                            disableAutoFocus={true}
                            placeholder="Select a date..."
                            ariaLabel="Data Arrivo"
                            label="Data Arrivo"
                            showGoToToday={false}
                            showWeekNumbers={false}
                            isRequired={true}
                            textField={(showDataArrivoError) ? { errorMessage: "Inserire Data Arrivo" } : null}
                            formatDate={(date) => { return (moment(date).format("DD/MM/YYYY")); }}
                            onSelectDate={(date) =>
                                updateFormData("DataArrivo", date)
                            }
                        />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <DatePicker
                            id="DataInvioInbound"
                            value={initialDataInvio}
                            strings={props.dayPickerStrings}
                            firstWeekOfYear={1}
                            firstDayOfWeek={1}
                            showMonthPickerAsOverlay={true}
                            disableAutoFocus={true}
                            placeholder="Select a date..."
                            ariaLabel="Data Invio"
                            label="Data Invio"
                            showGoToToday={false}
                            showWeekNumbers={false}
                            formatDate={(date) => { return (moment(date).format("DD/MM/YYYY")); }}
                            onSelectDate={(date) => {
                                updateFormData("DataInvio", date);
                                //return moment(date).format("YYYY-MM-DD");
                            }}
                        />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-3">
                        <TextField id="Mittente" label="Mittente" onChange={(event, value) => { updateFormData("Mittente", value) }} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-3">
                        <>
                            {/*<Dropdown*/}
                            {/*    id="Area"*/}
                            {/*    placeholder="Scegli Area"*/}
                            {/*    label="Area"*/}
                            {/*    options={props.optionsArea.filter(a => a.key !== 1 && a.key !== 2)}*/}
                            {/*    onChange={(event, item) => { updateFormData("Area", item.key) }}*/}
                            {/*    errorMessage={showAreaError ? 'Compila Area' : undefined}*/}
                            {/*    required*/}
                            {/*/>*/}
                            <ComboBox
                                id="Area"
                                placeholder="Scegli Area"
                                label="Area"
                                options={props.optionsArea.filter(a => a.key !== 1 && a.key !== 2)}
                                selectedKey={selectedArea}
                                onChange={(event, option, index) => {
                                    setSelectedArea(option.key);
                                    updateFormData("Area", option.key);
                                }}                                
                                errorMessage={showAreaError ? 'Compila Area' : undefined}
                                required
                                allowFreeform={true}
                                autoComplete="on"                                
                            />
                        </>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        {/*<Dropdown*/}
                        {/*    id="Mezzo"*/}
                        {/*    placeholder="Scegli Mezzooo"*/}
                        {/*    label="Mezzo"*/}
                        {/*    options={props.optionsMezzo}*/}
                        {/*    onChange={(event, item) => { updateFormData("Mezzo", item.key) }}*/}
                        {/*    errorMessage={showMezzoError ? 'Compila Mezzo' : undefined}*/}
                        {/*    required*/}
                        {/*/>*/}
                        <ComboBox
                            id="Mezzo"
                            placeholder="Scegli Mezzo"
                            label="Mezzo" 
                            options={props.optionsMezzo}
                            selectedKey={selectedMezzo}
                            onChange={(event, option, index) => {
                                setSelectedMezzo(option.key);
                                updateFormData("Mezzo", option.key);
                            }}
                            errorMessage={showMezzoError ? 'Compila Mezzo' : undefined}
                            required
                            allowFreeform={true}
                            autoComplete="on"
                        />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        {/*<Dropdown*/}
                        {/*    id="Ricezione"*/}
                        {/*    placeholder="Scegli Ricezione"*/}
                        {/*    label="Ricezione"*/}
                        {/*    options={props.optionsRicezione}*/}
                        {/*    onChange={(event, item) => { updateFormData("Ricezione", item.key) }}*/}
                        {/*/>*/}
                        <ComboBox
                            id="Ricezione"
                            placeholder="Scegli Ricezione"
                            label="Ricezione"
                            options={props.optionsRicezione}
                            selectedKey={selectedRicezione}
                            onChange={(event, option, index) => {
                                setSelectedRicezione(option.key);
                                updateFormData("Ricezione", option.key);
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-9">
                        {/*<Dropdown*/}
                        {/*    id="Documenti"*/}
                        {/*    placeholder="Scegli tipo documento"*/}
                        {/*    label="Tipo Documento"*/}
                        {/*    multiSelect*/}
                        {/*    options={props.optionsDocumento}*/}
                        {/*    onChange={(event, items) => { updateFormData("Documenti", items) }}*/}
                        {/*/>*/}

                        <ComboBox
                            id="Documenti"
                            placeholder="Scegli tipo documento"
                            label="Tipo Documento"
                            multiSelect
                            options={props.optionsDocumento}
                            selectedKey={selectedDocumentKeys}
                            //onChange={(event, items) => { updateFormData("Documenti", items) }}
                            onChange={(event, option, index) => {
                                debugger;
                                if (option) {
                                    
                                    let tmpSelectedDocKeys = [...selectedDocumentKeys];
                                    tmpSelectedDocKeys = option.selected ? [...tmpSelectedDocKeys, option.key] : tmpSelectedDocKeys.filter(k => k !== option.key)
                                    setSelectedDocumentKeys(tmpSelectedDocKeys);
                                    updateFormData("Documenti", tmpSelectedDocKeys);
                                }                                
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-9">
                        <TextField id="Note" label="Note" onChange={(event, value) => { updateFormData("Note", value) }} multiline autoAdjustHeight />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-9 text-right pt-4">
                        <DefaultButton type="submit" text={(isLoading) ? "Attendi..." : "Registra"} disabled={isLoading} allowDisabledFocus />
                    </div>
                </div>
            </form>
        );
    }

}
export default FormInbound