export const DayPickerStrings = {
    months: [
        "Gennaio",
        "Febbraio",
        "Marzo",
        "Aprile",
        "Maggo",
        "Giugno",
        "Luglio",
        "Agosto",
        "Settembre",
        "Ottobre",
        "Novembre",
        "Dicembre",
    ],
    shortMonths: [
        "Gen",
        "Feb",
        "Mar",
        "Apr",
        "Mag",
        "Giu",
        "Lug",
        "Ago",
        "Set",
        "Ott",
        "Nov",
        "Dic",
    ],
    days: [
        "Domenica",
        "Lunedì",
        "Martedì",
        "Mercoledì",
        "Giovedì",
        "Venerdì",
        "Sabato",
    ],
    shortDays: ["D", "L", "M", "M", "G", "V", "S"],
    goToToday: "Vai a Oggi",
    prevMonthAriaLabel: "Mese precedente",
    nextMonthAriaLabel: "Mese successivo",
    prevYearAriaLabel: "Anno precedente",
    nextYearAriaLabel: "Anno successivo",
    closeButtonAriaLabel: "Chiudi",
    monthPickerHeaderAriaLabel: "{0}, seleziona per cambiare anno",
    yearPickerHeaderAriaLabel: "{0}, seleziona per cambiare mese",
};