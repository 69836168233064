import React, { useState, useEffect, useContext } from 'react';
import ProtocolloContext from '../ProtocolloContext';
import { adalApiFetch, requestOptions } from '../adalConfig';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { DayPickerStrings } from './DayPickerStrings';
import Loading from './Loading';
import moment from 'moment';
import 'moment-timezone';
import './Outbound.css';
import {
    Stack,
    Dropdown,
    TextField,
    DefaultButton,
    PrimaryButton,
    DatePicker,
    Fabric,
    SearchBox,
    DetailsList,
    Modal,
    IconButton,
    getTheme,
    mergeStyleSets,
    FontWeights,
    Icon,
    ComboBox,
} from '@fluentui/react';

const Outbound = (props) => {

    initializeIcons(undefined, { disableWarnings: true });
    const [protocolloState, setProtocolloState] = useContext(ProtocolloContext);
    const [updateData, setUpdateData] = useState({ idProtocollo: '', fieldName: '', fieldValue: '', filedLabel: '' });
    const [isLoadingOptionLists, setIsLoadingOptionLists] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [outboundItems, setOutboundItems] = useState({});
    const [errorUpdatingMessage, setErrorUpdatingMessage] = useState("");
    //const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [resultsNumber, setResultsNumber] = useState(0);
    //const [resultsPerPage, setResultsPerPage] = useState(0);
    const [resultsShown, setResultsShown] = useState(0);
    const [showResults, setShowResults] = useState(false);
    const [optionsOggetto, setOptionsOggetto] = useState([]);
    const [optionsProvincia, setOptionsProvincia] = useState(JSON.parse(window.sessionStorage.getItem("HOME_LIST_Provincia")));
    const [optionsLocalita, setOptionsLocalita] = useState([]);
    const [optionsMittente, setOptionsMittente] = useState([]);
    const [optionsArea, setOptionsArea] = useState([]);
    const [optionsModalita, setOptionsModalita] = useState([]);
    const [selectedMittente, setSelectedMittente] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [selectedOggetto, setSelectedOggetto] = useState([]);
    const [selectedModalita, setSelectedModalita] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowDetails, SetSelectedRowDetails] = useState([]);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showEditTitolo, setShowEditTitolo] = useState(false);
    const [showEditArea, setShowEditArea] = useState(false);
    const [showEditModalita, setShowEditModalita] = useState(false);
    const [showEditOggetto, setShowEditOggetto] = useState(false);
    const [showEditMittente, setShowEditMittente] = useState(false);
    const [showEditRichiestoDa, setShowEditRichiestoDa] = useState(false);
    const [showEditCognome, setShowEditCognome] = useState(false);
    const [showEditNome, setShowEditNome] = useState(false);
    const [showEditIndirizzo, setShowEditIndirizzo] = useState(false);
    const [showEditCap, setShowEditCap] = useState(false);
    const [showEditCivico, setShowEditCivico] = useState(false);
    const [showEditNote, setShowEditNote] = useState(false);
    const [showEditDataRichiesta, setShowEditDataRichiesta] = useState(false);
    const [showEditDataInvio, setShowEditDataInvio] = useState(false);
    const [showEditProvincia, setShowEditProvincia] = useState(false);
    const [showEditLocalita, setShowEditLocalita] = useState(false);
    const [showEditCodiceRaccomandata, setShowEditCodiceRaccomandata] = useState(false);
    const [editDataInvioIntialValue, setEditDataInvioIntialValue] = useState(null);
    const [editDataRichiestaIntialValue, setEditDataRichiestaIntialValue] = useState(null);
    const [isDoingDelete, setIsDoingDelete] = useState(false);
    const [isDoingUpdate, setIsDoingUpdate] = useState(false);
    const [localitaIsText, setLocalitaIsText] = useState(false);
    const theme = getTheme();
    const stackTokens = { childrenGap: 10 };
    const iconDelete = { iconName: "Cancel" };
    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
        body: {
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            overflowY: "hidden",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
    });
    const iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: "auto",
            marginTop: "4px",
            marginRight: "2px",
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };
    const generateGetOutboundUrl = (page) => {
        var urlToCall = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/protocollooutbound?context=${protocolloState.context}&cognome={cognome}&periodoDal={periodoDal}&periodoAl={periodoAl}&area={area}&oggetto={oggetto}&mittente={mittente}&modalita={modalita}&page={page}&limitToLegalAndServices=false`;
        page = page != null ? page : currentPage;
        //PERIODO DAL
        var periodoDal = window.sessionStorage.getItem("OUT_PeriodoDal");
        urlToCall =
            periodoDal !== "" && periodoDal !== null && periodoDal.length > 0
                ? urlToCall.replace("{periodoDal}", moment(periodoDal).format("YYYY-MM-DD"))
                : urlToCall.replace("{periodoDal}", "");
        //PERIODO AL
        var periodoAl = window.sessionStorage.getItem("OUT_PeriodoAl");
        urlToCall =
            periodoAl !== "" && periodoAl !== null && periodoAl.length > 0
                ? urlToCall.replace("{periodoAl}", moment(periodoAl).format("YYYY-MM-DD"))
                : urlToCall.replace("{periodoAl}", "");
        //COGNOME
        var cognome = window.sessionStorage.getItem("OUT_Cognome");
        urlToCall =
            cognome !== "" && cognome !== null && cognome.length > 0
                ? urlToCall.replace("{cognome}", cognome)
                : urlToCall.replace("{cognome}", "");
        //AREA
        var area = window.sessionStorage.getItem("OUT_Area");
        if (area !== "" && area !== null && area.length > 0) {
            var AreaArray = JSON.parse(area);
            let stringToAppend = "";
            AreaArray.forEach((item, index) => {
                stringToAppend += item.key + ",";
            });
            urlToCall = urlToCall.replace(
                "{area}",
                stringToAppend.replace(/,\s*$/, "")
            );
        } else {
            urlToCall = urlToCall.replace("{area}", "");
        }
        //MITTENTE
        var mittente = window.sessionStorage.getItem("OUT_Mittente");
        if (mittente !== "" && mittente !== null && mittente.length > 0) {
            var MittenteArray = JSON.parse(mittente);
            let stringToAppend = "";
            MittenteArray.forEach((item, index) => {
                stringToAppend += item.key + ",";
            });
            urlToCall = urlToCall.replace(
                "{mittente}",
                stringToAppend.replace(/,\s*$/, "")
            );
        } else {
            urlToCall = urlToCall.replace("{mittente}", "");
        }
        //MODALITA
        var modalita = window.sessionStorage.getItem("OUT_Modalita");
        if (modalita !== "" && modalita !== null && modalita.length > 0) {
            var ModalitaArray = JSON.parse(modalita);
            let stringToAppend = "";
            ModalitaArray.forEach((item, index) => {
                stringToAppend += item.key + ",";
            });
            urlToCall = urlToCall.replace(
                "{modalita}",
                stringToAppend.replace(/,\s*$/, "")
            );
        } else {
            urlToCall = urlToCall.replace("{modalita}", "");
        }
        //OGGETTO
        var oggetto = window.sessionStorage.getItem("OUT_Oggetto");
        if (oggetto !== "" && oggetto !== null && oggetto.length > 0) {
            var OggettoArray = JSON.parse(oggetto);
            let stringToAppend = "";
            OggettoArray.forEach((item, index) => {
                stringToAppend += item.key + ",";
            });
            urlToCall = urlToCall.replace(
                "{oggetto}",
                stringToAppend.replace(/,\s*$/, "")
            );
        } else {
            urlToCall = urlToCall.replace("{oggetto}", "");
        }
        //PAGE
        urlToCall =
            page !== "" && page !== null
                ? urlToCall.replace("{page}", page)
                : urlToCall.replace("{page}", 1);
        return urlToCall;
    };
    const outboundColumns = [
        {
            key: "dataRichiesta",
            name: "Data Ric.",
            fieldName: "dataRichiesta",
            minWidth: 70,
            maxWidth: 70,
            isResizable: false,
        },
        {
            key: "titolo",
            name: "Titolo",
            fieldName: "titolo",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
        },
        {
            key: "area",
            name: "Area",
            fieldName: "area",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "mittente",
            name: "Mittente",
            fieldName: "mittente",
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
        },
        /*{
            key: "modalita",
            name: "Modalita",
            fieldName: "modalita",
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
        },*/
        {
            key: "oggetto",
            name: "Oggetto",
            fieldName: "oggetto",
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
        },
        {
            key: "dataInserimento",
            name: "Inserito il",
            fieldName: "dataInserimento",
            minWidth: 70,
            maxWidth: 70,
            isResizable: false,
        },
    ];
    const selectItems = (itemName, valueToAdd) => {
        var ItemsSaved = window.sessionStorage.getItem("OUT_"+itemName);
        if (ItemsSaved == null) {
            ItemsSaved = JSON.stringify([valueToAdd]);
            window.sessionStorage.setItem("OUT_"+itemName, ItemsSaved);
        } else {
            var ItemsArray = JSON.parse(ItemsSaved);
            if (valueToAdd.selected) {
                //ADD
                ItemsArray.pushIfNotExist(valueToAdd, function (e) {
                    return e.key === valueToAdd.key && e.text === valueToAdd.text;
                });
            } else {
                //REMOVE
                ItemsArray.forEach((item, index) => {
                    if (item.key === valueToAdd.key) {
                        ItemsArray.splice(index, 1);
                    }
                });
            }
            if (ItemsArray.length > 0) {
                ItemsSaved = JSON.stringify(ItemsArray);
                window.sessionStorage.setItem("OUT_"+itemName, ItemsSaved);
            } else {
                window.sessionStorage.removeItem("OUT_" +itemName);
            }
            //console.log(ItemsArray);
        }
    };
    const setFieldCookie = (itemName, valueToUpdate) => {
        if (valueToUpdate !== "")
            window.sessionStorage.setItem("OUT_"+itemName, valueToUpdate);
        else window.sessionStorage.removeItem("OUT_" +itemName);
    };
    const clearSearchForm = () => {
        window.sessionStorage.removeItem("OUT_Area");
        window.sessionStorage.removeItem("OUT_Mittente");
        window.sessionStorage.removeItem("OUT_Modalita");
        window.sessionStorage.removeItem("OUT_Oggetto");
        window.sessionStorage.removeItem("OUT_Cognome");
        window.sessionStorage.removeItem("OUT_PeriodoDal");
        window.sessionStorage.removeItem("OUT_PeriodoAl");
        document.location.reload();
    };
    const LoadOutboundList = async (page, clearList = true) => {
        if (!isLoading) {
            setIsLoading(true);
            var btn = document.getElementById("btnSearch");
            btn.disabled = true;
            btn.innerHTML = "Loading";
            if (clearList) {
                setOutboundItems([]);
                setResultsShown("--");
                setResultsNumber("--");
            }
           
            var url = generateGetOutboundUrl(page);
            var optionsGet = requestOptions; 
            optionsGet.method = 'get';
            optionsGet.body = null;
            await adalApiFetch(fetch, url, optionsGet)
                .then((response) => response.json())
                .then((data) => {
                    if (data != null) {
                        if (data.totalResults !== null) {
                            //console.log(data);
                            setResultsNumber(data.totalResults);
                            if (page <= 1) {
                                setOutboundItems(data.results);
                            } else {
                                var tempOutboundItems = [...outboundItems];
                                var newList = tempOutboundItems.concat(data.results);
                                setOutboundItems(newList);
                            }
                            if (data.pages <= 1 || page <= 1) {
                                setResultsShown(data.results.length);
                            } else {
                                setResultsShown(resultsShown + data.results.length);
                            }
                            setCurrentPage(data.page);
                            //setTotalPages(data.pages);
                            //setResultsPerPage(data.resultsPerPage);
                            setShowResults(true);
                            btn.disabled = false;
                            btn.innerHTML = "Cerca";
                            setIsLoading(false);
                        } else {
                            btn.disabled = false;
                            btn.innerHTML = "Cerca";
                            setIsLoading(false);
                            return setOutboundItems({});
                        }
                    } else {
                        btn.disabled = false;
                        btn.innerHTML = "Cerca";
                        setIsLoading(false);
                        return setOutboundItems({});
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const loadMore = () => {
        var page = currentPage + 1;
        setCurrentPage(page);
        LoadOutboundList(page, false);
    };
    const LoadOptionsList = async (optionListName) => {

        var cachedOptionList = sessionStorage.getItem("OUTBOUND_LIST_" + optionListName);
        if (cachedOptionList) {
            if (optionListName === "Area") {
                setOptionsArea(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Oggetto") {
                setOptionsOggetto(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Modalita") {
                setOptionsModalita(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Mittente") {
                setOptionsMittente(JSON.parse(cachedOptionList));
            }
        } else {
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/optionsList?context=${protocolloState.context}&optionListName=${optionListName}`;
            var optionsGet = requestOptions;
            optionsGet.method = 'get';
            optionsGet.body = null;
            await adalApiFetch(fetch, url, optionsGet)
                .then((response) => response.json())
                .then((data) => {
                    if (optionListName === "Area") {
                        let newData = [...data];
                        newData.unshift({ text: "Nessuna", key: "", });
                        setOptionsArea(newData);
                        sessionStorage.setItem("OUTBOUND_LIST_" + optionListName, JSON.stringify(newData));
                    }
                    if (optionListName === "Oggetto") {
                        setOptionsOggetto(data);
                        sessionStorage.setItem("OUTBOUND_LIST_" + optionListName, JSON.stringify(data));
                    }
                    if (optionListName === "Modalita") {
                        let newData = [...data];
                        newData.unshift({ text: "Non specificato", key: "", });
                        setOptionsModalita(newData);
                        sessionStorage.setItem("OUTBOUND_LIST_" + optionListName, JSON.stringify(newData));
                    }
                    if (optionListName === "Mittente") {
                        let newData = [...data];
                        newData.unshift({ text: "Non specificato", key: "", });
                        setOptionsMittente(newData);
                        sessionStorage.setItem("OUTBOUND_LIST_" + optionListName, JSON.stringify(newData));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const loadCookies = async (optionListName) => {
        var ArraySelected = [];
        var ItemsSaved = window.sessionStorage.getItem("OUT_"+optionListName);
        if (ItemsSaved != null) {
            var ItemsArray = JSON.parse(ItemsSaved);
            if (ItemsArray.length > 0) {
                ItemsArray.forEach((item, index) => {
                    ArraySelected.push(item.key);
                });
            }
        }
        if (optionListName === "Area") {
            setSelectedArea(ArraySelected);
        }
        if (optionListName === "Oggetto") {
            setSelectedOggetto(ArraySelected);
        }
        if (optionListName === "Modalita") {
            setSelectedModalita(ArraySelected);
        }
        if (optionListName === "Mittente") {
            setSelectedMittente(ArraySelected);
        }
    };
    const rowClick = async (item, index) => {
        setIsModalOpen(true);
        SetSelectedRowDetails(item);
        loadLocalita(item.anagrafica.provincia);
    };
    const deleteProtocollo = async (idProtocollo) => {
        setIsDoingDelete(true);
        if (isNaN(idProtocollo)) {
            setErrorUpdatingMessage(`Si sono verificati errori durante l'eliminazione, id protocollo non valido.`);
            setIsDoingDelete(false);
            return false;
        }
        else {
            //console.log("Elimino protocollo ", idProtocollo);
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/protocollooutbound`;
            var optionsPost = requestOptions;
            optionsPost.method = 'delete';
            optionsPost.body = JSON.stringify({ idProtocollo: idProtocollo, Context: protocolloState.context });
            await adalApiFetch(fetch, url, optionsPost)
                .then((response) => response.json())
                .then((data) => {
                    if (data.deleteStatus === true) {
                        var temp = [...outboundItems];
                        var newList = temp.filter((obj => obj.idProtocollo !== idProtocollo));
                        setOutboundItems(newList);
                        setErrorUpdatingMessage(``);
                        setIsModalOpen(false);
                        setShowConfirmDelete(false);
                        return true;
                    }
                    else if (data.deleteStatus === false) {
                        setErrorUpdatingMessage(`Si sono verificati errori durante l'eliminazione.`);
                        return false;
                    }

                    if (data.error && data.error !== undefined && data.error !== "") {
                        setErrorUpdatingMessage(`Si sono verificati errori durante l'eliminazione. ${data.error}`);
                        return false;
                    }

                    setIsDoingDelete(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsDoingDelete(false);
                });
        }
    }
    const handleShowEditOutboundField = (fieldName, show) => {
        setErrorUpdatingMessage("");
        setUpdateData({ idProtocollo: '', fieldName: '', fieldValue: '', fieldLabel: '' });
        switch (fieldName) {
            case 'Area':
                setShowEditArea(show);
                break;
            case 'Modalita':
                setShowEditModalita(show);
                break;
            case 'Titolo':
                setShowEditTitolo(show);
                break;
            case 'RichiestoDa':
                setShowEditRichiestoDa(show);
                break;
            case 'Cognome':
                setShowEditCognome(show);
                break;
            case 'Nome':
                setShowEditNome(show);
                break;
            case 'Indirizzo':
                setShowEditIndirizzo(show);
                break;
            case 'Civico':
                setShowEditCivico(show);
                break;
            case 'Cap':
                setShowEditCap(show);
                break;
            case 'Note':
                setShowEditNote(show);
                break;
            case 'CodiceRaccomandata':
                setShowEditCodiceRaccomandata(show);
                break;
            case 'Oggetto':
                setShowEditOggetto(show);
                break;
            case 'Mittente':
                setShowEditMittente(show);
                break;
            case 'DataInvio':
                setShowEditDataInvio(show);
                break;
            case 'DataRichiesta':
                setShowEditDataRichiesta(show);
                break;
            case 'Provincia':
                setShowEditProvincia(show);
                break;
            case 'Localita':
                setShowEditLocalita(show);
                break;
        }
    }
    const resetAllEditFields = () => {
        setErrorUpdatingMessage("");
        setShowEditArea(false);
        setShowEditTitolo(false);
        setShowEditRichiestoDa(false);
        setShowEditCognome(false);
        setShowEditNome(false);
        setShowEditIndirizzo(false);
        setShowEditCivico(false);
        setShowEditCap(false);
        setShowEditNote(false);
        setShowEditCodiceRaccomandata(false);
        setShowEditOggetto(false);
        setShowEditModalita(false);
        setShowEditMittente(false);
        setShowEditDataInvio(false);
        setShowEditDataRichiesta(false);
        setShowEditProvincia(false);
        setShowEditLocalita(false);
    }
    const fetchUpdateOutbound = async (selectedRowDetails) => {
        setErrorUpdatingMessage("");
        setIsDoingUpdate(true);
        var blockSend = false;
        if (updateData.idProtocollo === "" ||
            updateData.idProtocollo === undefined ||
            updateData.fieldLabel === undefined ||
            updateData.fieldName === "" ||
            updateData.fieldName === undefined ||
            (updateData.fieldName !== "Note" && updateData.fieldName !== "Oggetto" && updateData.fieldName !== "DataInvio" && updateData.fieldValue === "") ||
            updateData.fieldValue === undefined
        ) {
            blockSend = true;
        }
        if (blockSend) {
            setIsDoingUpdate(false);
            return false;
        }
        else {
            document.getElementById("btnUpdateOutbound" + updateData.fieldName).disabled = true;
            setIsDoingUpdate(true);
            updateData.context = protocolloState.context;
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/protocollooutbound`;
            var optionsPost = requestOptions;
            optionsPost.method = 'put';
            optionsPost.body = JSON.stringify(updateData);
            await adalApiFetch(fetch, url, optionsPost)
                .then((response) => response.json())
                .then((data) => {
                    if (data.updateStatus === true) {
                        document.getElementById("ValueLabel" + updateData.fieldName).innerHTML = updateData.fieldLabel;
                        if (updateData.fieldName === "Provincia") {
                            document.getElementById("ValueLabelLocalita").innerHTML = "";
                            loadLocalita(updateData.fieldValue);
                        }
                        SetSelectedRowDetails(selectedRowDetails);
                        handleShowEditOutboundField(updateData.fieldName, false)
                    }
                    else if (data.updateStatus === false) {
                        setErrorUpdatingMessage(`Si sono verificati errori durante l'aggiornamento del campo ${updateData.fieldName}`);
                    }
                    setIsDoingUpdate(false);
                    document.getElementById("btnUpdateOutbound" + updateData.fieldName).disabled = false;
                })
                .catch((error) => {
                    console.log(error);
                    setIsDoingUpdate(false);
                });
        }
    }
    const loadLocalita = async (provincia) => {
        if (provincia !== "EE") {
            setLocalitaIsText(false);
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/optionsList?context=${protocolloState.context}&optionListName=Localita&provincia=${provincia}`;
            var optionsGet = requestOptions;
            optionsGet.method = 'get';
            optionsGet.body = null;
            await adalApiFetch(fetch, url, optionsGet)
                .then((response) => response.json())
                .then((data) => {
                    setOptionsLocalita(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setLocalitaIsText(true);
        }
        
    }
    useEffect(() => {
        async function fetchOptionsList() {
            window.sessionStorage.setItem("OUT_PeriodoDal", new Date(new Date().getFullYear(), new Date().getMonth(), 1))
            window.sessionStorage.setItem("OUT_PeriodoAl", new Date())
            setIsLoadingOptionLists(true);
            await loadCookies("Mittente");
            await LoadOptionsList("Mittente");
            await loadCookies("Oggetto");
            await LoadOptionsList("Oggetto");
            await loadCookies("Area");
            await LoadOptionsList("Area");
            await loadCookies("Modalita");
            await LoadOptionsList("Modalita");
            setIsLoadingOptionLists(false);
        }
        fetchOptionsList();
    }, []);
    return (isLoadingOptionLists) ?
        <Loading message="Attendere..." />
        :
        <div>
            <h1><div className="ms-font-su ms-fontColor-themePrimary">Uscita</div></h1>
            <Fabric>
                <Stack tokens={stackTokens}>
                    <form id="protocolloSearchForm" method="POST">
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <DatePicker id="PeriodoDal"
                                        strings={DayPickerStrings}
                                        disableAutoFocus={true}
                                        value={
                                            window.sessionStorage.getItem("OUT_PeriodoDal") != null
                                                ? new Date(window.sessionStorage.getItem("OUT_PeriodoDal"))
                                                : new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                                        }
                                        maxDate={
                                            window.sessionStorage.getItem("OUT_PeriodoAl") != null
                                                ? new Date(window.sessionStorage.getItem("OUT_PeriodoAl"))
                                                : null
                                        }
                                        firstWeekOfYear={1}
                                        firstDayOfWeek={1}
                                        showMonthPickerAsOverlay={true}
                                        placeholder="Select a date..."
                                        ariaLabel="Periodo Dal"
                                        label="Periodo Dal"
                                        showGoToToday={false}
                                        showWeekNumbers={false}
                                        formatDate={(date) => {
                                            return (
                                                date.getDate() +
                                                "/" +
                                                (date.getMonth() + 1) +
                                                "/" +
                                                date.getFullYear()
                                            );
                                        }}
                                        onSelectDate={(date) =>
                                            window.sessionStorage.setItem("OUT_PeriodoDal", date)
                                        }
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    <DatePicker id="PeriodoAl"
                                        strings={DayPickerStrings}
                                        value={
                                            window.sessionStorage.getItem("OUT_PeriodoAl") != null
                                                ? new Date(window.sessionStorage.getItem("OUT_PeriodoAl"))
                                                : new Date()
                                        }
                                        minDate={
                                            window.sessionStorage.getItem("OUT_PeriodoDal") != null
                                                ? new Date(window.sessionStorage.getItem("OUT_PeriodoDal"))
                                                : null
                                        }
                                        firstWeekOfYear={1}
                                        firstDayOfWeek={1}
                                        showMonthPickerAsOverlay={true}
                                        disableAutoFocus={true}
                                        placeholder="Select a date..."
                                        ariaLabel="Periodo Al"
                                        label="Periodo Al"
                                        showGoToToday={false}
                                        showWeekNumbers={false}
                                        formatDate={(date) => {
                                            return (
                                                date.getDate() +
                                                "/" +
                                                (date.getMonth() + 1) +
                                                "/" +
                                                date.getFullYear()
                                            );
                                        }}
                                        onSelectDate={(date) =>
                                            window.sessionStorage.setItem("OUT_PeriodoAl", date)
                                        }
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                    <div className="ms-TextField-wrapper">
                                        <label className="protocolloLabels">Cognome</label>
                                        <SearchBox id="Cognome"
                                            placeholder="Inserisci cognome"
                                            value={window.sessionStorage.getItem("OUT_Cognome")}
                                            onChange={(item, value) =>
                                                setFieldCookie("Cognome", value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="ms-Grid-row ">
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    {/*<Dropdown id="Area"*/}
                                    {/*    placeholder="Scegli Area"*/}
                                    {/*    label="Area"*/}
                                    {/*    multiSelect*/}
                                    {/*    options={optionsArea}*/}
                                    {/*    defaultSelectedKeys={selectedArea}*/}
                                    {/*    onChange={(item, selectedOption) =>*/}
                                    {/*        selectItems("Area", selectedOption)*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                    <ComboBox id="Area"
                                        placeholder="Scegli Area"
                                        label="Area"
                                        multiSelect
                                        allowFreeform={true}
                                        autoComplete="on"
                                        options={optionsArea}
                                        defaultSelectedKeys={selectedArea}
                                        onChange={(item, selectedOption) => {
                                            selectItems("Area", selectedOption)
                                        }}
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    {/*<Dropdown placeholder="Scegli Mittente"*/}
                                    {/*    label="Mittente"*/}
                                    {/*    multiSelect*/}
                                    {/*    options={optionsMittente}*/}
                                    {/*    defaultSelectedKeys={selectedMittente}*/}
                                    {/*    onChange={(item, selectedOption) =>*/}
                                    {/*        selectItems("Mittente", selectedOption)*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                    <ComboBox
                                        placeholder="Scegli Mittente"
                                        label="Mittente"
                                        multiSelect
                                        allowFreeform={true}
                                        autoComplete="on"
                                        options={optionsMittente}
                                        defaultSelectedKeys={selectedMittente}
                                        onChange={(item, selectedOption) =>
                                            selectItems("Mittente", selectedOption)
                                        }
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    {/*<Dropdown placeholder="Scegli oggetto"*/}
                                    {/*    label="Tipo Oggetto"*/}
                                    {/*    multiSelect*/}
                                    {/*    options={optionsOggetto}*/}
                                    {/*    defaultSelectedKeys={selectedOggetto}*/}
                                    {/*    onChange={(item, selectedOption) =>*/}
                                    {/*        selectItems("Oggetto", selectedOption)*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                    <ComboBox
                                        placeholder="Scegli oggetto"
                                        label="Tipo Oggetto"
                                        multiSelect
                                        allowFreeform={true}
                                        autoComplete="on"
                                        options={optionsOggetto}
                                        defaultSelectedKeys={selectedOggetto}
                                        onChange={(item, selectedOption) =>
                                            selectItems("Oggetto", selectedOption)
                                        }
                                    />
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                    {/*<Dropdown placeholder="Scegli Modalita"*/}
                                    {/*    label="Modalita"*/}
                                    {/*    multiSelect*/}
                                    {/*    options={optionsModalita}*/}
                                    {/*    defaultSelectedKeys={selectedModalita}*/}
                                    {/*    onChange={(item, selectedOption) =>*/}
                                    {/*        selectItems("Modalita", selectedOption)*/}
                                    {/*    }*/}
                                    {/*/>*/}
                                    <ComboBox
                                        placeholder="Scegli Modalita"
                                        label="Modalita"
                                        multiSelect
                                        allowFreeform={true}
                                        autoComplete="on"
                                        options={optionsModalita}
                                        defaultSelectedKeys={selectedModalita}
                                        onChange={(item, selectedOption) =>
                                            selectItems("Modalita", selectedOption)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="ms-Grid-row"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    "marginTop": "10px",
                                }}>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                    {showResults ? (
                                        <div>
                                            {resultsShown} Risultati mostrati su {resultsNumber}{" "}
                                            Trovati
                            </div>
                                    ) : null}
                                </div>
                                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"
                                    style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <DefaultButton text="Pulisci"
                                        style={{ "marginRight": "5px" }}
                                        onClick={() =>
                                            clearSearchForm()}
                                    />
                                    <PrimaryButton id="btnSearch"
                                        text="Cerca"
                                        style={{ minWidth: "100px" }}
                                        onClick={() =>
                                            LoadOutboundList(1)}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                    <div id="risultati-container" className="ms-Grid-row">
                        {showResults ? (
                            <DetailsList compact={true}
                                items={outboundItems}
                                checkboxVisibility={2}
                                columns={outboundColumns}
                                setKey="set"
                                selectionPreservedOnEmptyClick={true}
                                onActiveItemChanged={(item) =>
                                    rowClick(item)}
                                onItemInvoked={(item) => rowClick(item)}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="Row checkbox"
                            />
                        ) : null}
                    </div>
                    {showResults && resultsShown < resultsNumber ? (
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div id="loadMore" className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {!isLoading ? (
                                        <DefaultButton
                                            id="btnLoadMore"
                                            onClick={() => loadMore()}
                                            text="Carica altro"
                                            allowDisabledFocus />
                                    ) : (
                                            <div>Loading...</div>
                                        )}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <Modal titleAriaId="ModalDetails"
                        isOpen={isModalOpen}
                        onDismiss={() =>
                            setIsModalOpen(false)}
                        isBlocking={false}
                        containerClassName={contentStyles.container}
                    >


                        <div className={contentStyles.header}>
                            <span id="ModalDetailsTitle" style={{ minWidth: (protocolloState.roleIsReadOnly) ? "650px" : "950px" }}>
                                #{selectedRowDetails.idProtocollo}
                            </span>
                            <IconButton styles={iconButtonStyles}
                                iconProps={iconDelete}
                                ariaLabel="Close popup modal"
                                onClick={() => { setIsModalOpen(false); resetAllEditFields(); }
                                }
                            />
                        </div>

                        <div className={contentStyles.body} id="ModalDetailsContent">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
                                    <h6>Dati Protocollo</h6>
                                    <hr />

                                    {/*TITOLO*/}
                                    <div className="DetailsRow d-flex flex-wrap">
                                        <div className={(showEditTitolo) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                            <div>
                                                <Icon iconName="Header" /> <b>Titolo:</b>{" "}
                                                <span id="ValueLabelTitolo">{selectedRowDetails.titolo}</span>
                                            </div>
                                            {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditTitolo) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Titolo", true)}>[modifica]</a>}
                                        </div>
                                        <div className={(showEditTitolo) ? "col-12 d-inline mt-2" : "col-12 d-none mt-2"}>
                                            <div className={(showEditTitolo) ? "d-inline" : "d-none"}>
                                                <TextField
                                                    id="Titolo"
                                                    defaultValue={selectedRowDetails.titolo}
                                                    onChange={(event, value) => {
                                                        setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Titolo", fieldValue: value, fieldLabel: value })
                                                    }}
                                                />
                                                <DefaultButton text="Annulla"
                                                    style={{ "marginRight": "5px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        handleShowEditOutboundField("Titolo", false);
                                                    }}
                                                />
                                                <PrimaryButton
                                                    id="btnUpdateOutboundTitolo"
                                                    text="Salva"
                                                    style={{ minWidth: "100px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.titolo = updateData.fieldLabel; })
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*RICHIESTO DA*/}
                                    <div className="DetailsRow d-flex flex-wrap">
                                        <div className={(showEditRichiestoDa) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                            <div>
                                                <Icon iconName="UserOptional" /> <b>Richiesto Da:</b>{" "}
                                                <span id="ValueLabelRichiestoDa">{selectedRowDetails.richiestoDa}</span>
                                            </div>
                                            {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditRichiestoDa) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("RichiestoDa", true)}>[modifica]</a>}
                                        </div>
                                        <div className={(showEditRichiestoDa) ? "col-12 d-inline mt-2" : "col-12 d-none mt-2"}>
                                            <div className={(showEditRichiestoDa) ? "d-inline" : "d-none"}>
                                                <TextField
                                                    id="RichiestoDa"
                                                    defaultValue={selectedRowDetails.richiestoDA}
                                                    onChange={(event, value) => {
                                                        setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "RichiestoDa", fieldValue: value, fieldLabel: value })
                                                    }}
                                                />
                                                <DefaultButton text="Annulla"
                                                    style={{ "marginRight": "5px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        handleShowEditOutboundField("RichiestoDa", false);
                                                    }}
                                                />
                                                <PrimaryButton
                                                    id="btnUpdateOutboundRichiestoDa"
                                                    text="Salva"
                                                    style={{ minWidth: "100px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.richiestoDa = updateData.fieldLabel; })
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*AREA*/}
                                    <div className="DetailsRow d-flex flex-wrap">
                                        <div className={(showEditArea) ? "d-flex justify-content-between col-12" : "d-flex justify-content-between col-12"}>
                                            <div>
                                                <Icon iconName="Telemarketer" /> <b>Area<sup className="text-danger">*</sup> :</b>{" "}
                                                <span id="ValueLabelArea">{selectedRowDetails.area}</span>
                                            </div>
                                            {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditArea) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Area", true)}>[modifica]</a>}
                                        </div>
                                        <div className={(showEditArea) ? "col-12 d-inline" : "col-12 d-none"}>
                                            <div className={(showEditArea) ? "d-inline" : "d-none"}>
                                                <Dropdown
                                                    id="EditArea"
                                                    name="Area"
                                                    placeholder="Scegli Area"
                                                    options={optionsArea}
                                                    onChange={(item, selectedOption) =>
                                                        setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Area", fieldValue: selectedOption.key, fieldLabel: selectedOption.text })
                                                    }
                                                />
                                                <DefaultButton text="Annulla"
                                                    style={{ "marginRight": "5px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        handleShowEditOutboundField("Area", false)
                                                    }}
                                                />
                                                <PrimaryButton
                                                    id="btnUpdateOutboundArea"
                                                    text="Salva"
                                                    style={{ minWidth: "100px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        fetchUpdateOutbound(selectedRowDetails).then(() => {
                                                            selectedRowDetails.area = updateData.fieldLabel;
                                                            selectedRowDetails.idArea = updateData.fieldValue;
                                                        });
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*DATA RICHIESTA*/}
                                    <div className="DetailsRow d-flex flex-wrap">
                                        <div className={(showEditDataRichiesta) ? "d-flex justify-content-between col-12" : "d-flex justify-content-between col-12"}>
                                            <div>
                                                <Icon iconName="CalendarReply" /> <b>Data richiesta<sup className="text-danger">*</sup> :</b>{" "}
                                                <span id="ValueLabelDataRichiesta">{selectedRowDetails.dataRichiesta}</span>
                                            </div>
                                            {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditDataRichiesta) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("DataRichiesta", true)}>[modifica]</a>}
                                        </div>
                                        <div className={(showEditDataRichiesta) ? "col-12 d-inline" : "col-12 d-none"}>
                                            <div className={(showEditDataRichiesta) ? "d-inline" : "d-none"}>
                                                <DatePicker
                                                    id="EditDataRichiesta"
                                                    name="DataRichiesta"
                                                    strings={DayPickerStrings}
                                                    disableAutoFocus={true}
                                                    value={editDataRichiestaIntialValue}
                                                    firstWeekOfYear={1}
                                                    firstDayOfWeek={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Scegli data richiesta..."
                                                    showGoToToday={false}
                                                    showWeekNumbers={false}
                                                    maxDate={new Date()}
                                                    formatDate={(date) => {
                                                        return (
                                                            date.getDate() +
                                                            "/" +
                                                            (date.getMonth() + 1) +
                                                            "/" +
                                                            date.getFullYear()
                                                        );
                                                    }}
                                                    onSelectDate={(date) => {
                                                        setEditDataRichiestaIntialValue(date);
                                                        setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "DataRichiesta", fieldValue: date, fieldLabel: moment(date).format("DD/MM/YYYY") })
                                                    }}
                                                />
                                                <DefaultButton text="Annulla"
                                                    style={{ "marginRight": "5px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        handleShowEditOutboundField("DataRichiesta", false)
                                                    }}
                                                />
                                                <PrimaryButton
                                                    id="btnUpdateOutboundDataRichiesta"
                                                    text="Salva"
                                                    style={{ minWidth: "100px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.dataRichiesta = updateData.fieldLabel; });
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*DATA INVIO*/}
                                    <div className="DetailsRow d-flex flex-wrap">
                                        <div className={(showEditDataInvio) ? "d-flex justify-content-between col-12" : "d-flex justify-content-between col-12"}>
                                            <div>
                                                <Icon iconName="CalendarReply" /> <b>Data invio :</b>{" "}
                                                <span id="ValueLabelDataInvio">{selectedRowDetails.dataInvio}</span>
                                            </div>
                                            {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditDataInvio) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("DataInvio", true)}>[modifica]</a>}
                                        </div>
                                        <div className={(showEditDataInvio) ? "col-12 d-inline" : "col-12 d-none"}>
                                            <div className={(showEditDataInvio) ? "d-inline" : "d-none"}>
                                                <DatePicker
                                                    id="EditDataInvio"
                                                    name="DataInvio"
                                                    strings={DayPickerStrings}
                                                    disableAutoFocus={true}
                                                    value={editDataInvioIntialValue}
                                                    firstWeekOfYear={1}
                                                    firstDayOfWeek={1}
                                                    showMonthPickerAsOverlay={true}
                                                    placeholder="Scegli data invio..."
                                                    showGoToToday={false}
                                                    showWeekNumbers={false}
                                                    maxDate={new Date()}
                                                    formatDate={(date) => {
                                                        return (
                                                            date.getDate() +
                                                            "/" +
                                                            (date.getMonth() + 1) +
                                                            "/" +
                                                            date.getFullYear()
                                                        );
                                                    }}
                                                    onSelectDate={(date) => {
                                                        setEditDataInvioIntialValue(date);
                                                        setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "DataInvio", fieldValue: date, fieldLabel: moment(date).format("DD/MM/YYYY") })
                                                    }}
                                                />
                                                <DefaultButton text="Annulla"
                                                    style={{ "marginRight": "5px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        handleShowEditOutboundField("DataInvio", false)
                                                    }}
                                                />
                                                <PrimaryButton
                                                    id="btnUpdateOutboundDataInvio"
                                                    text="Salva"
                                                    style={{ minWidth: "100px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.dataInvio = updateData.fieldLabel; });
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*OGGETTO*/}
                                    <div className="DetailsRow d-flex flex-wrap">
                                        <div className={(showEditOggetto) ? "d-flex justify-content-between col-12" : "d-flex justify-content-between col-12"}>
                                            <div>
                                                <Icon iconName="Documentation" /> <b>Oggetto<sup className="text-danger">*</sup> :</b>{" "}
                                                <span id="ValueLabelOggetto">{selectedRowDetails.oggetto}</span>
                                            </div>
                                            {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditOggetto) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Oggetto", true)}>[modifica]</a>}
                                        </div>
                                        <div className={(showEditOggetto) ? "col-12 d-inline" : "col-12 d-none"}>
                                            <div className={(showEditOggetto) ? "d-inline" : "d-none"}>
                                                <Dropdown
                                                    id="EditOggetto"
                                                    name="Oggetto"
                                                    placeholder="Scegli Oggetto"
                                                    options={optionsOggetto}
                                                    onChange={(item, selectedOption) =>
                                                        setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Oggetto", fieldValue: selectedOption.key, fieldLabel: selectedOption.text })
                                                    }
                                                />
                                                <DefaultButton text="Annulla"
                                                    style={{ "marginRight": "5px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        handleShowEditOutboundField("Oggetto", false)
                                                    }}
                                                />
                                                <PrimaryButton
                                                    id="btnUpdateOutboundOggetto"
                                                    text="Salva"
                                                    style={{ minWidth: "100px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        fetchUpdateOutbound(selectedRowDetails).then(() => {
                                                            selectedRowDetails.oggetto = updateData.fieldLabel;
                                                            selectedRowDetails.idOggetto = updateData.fieldValue;
                                                        });
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*MITTENTE*/}
                                    <div className="DetailsRow d-flex flex-wrap">
                                        <div className={(showEditMittente) ? "d-flex justify-content-between col-12" : "d-flex justify-content-between col-12"}>
                                            <div>
                                                <Icon iconName="Send" /> <b>Mittente<sup className="text-danger">*</sup> :</b>{" "}
                                                <span id="ValueLabelMittente">{selectedRowDetails.mittente}</span>
                                            </div>
                                            {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditMittente) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Mittente", true)}>[modifica]</a>}
                                        </div>
                                        <div className={(showEditMittente) ? "col-12 d-inline" : "col-12 d-none"}>
                                            <div className={(showEditMittente) ? "d-inline" : "d-none"}>
                                                <Dropdown
                                                    id="EditMittente"
                                                    name="Mittente"
                                                    placeholder="Scegli Mittente"
                                                    options={optionsMittente}
                                                    onChange={(item, selectedOption) =>
                                                        setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Mittente", fieldValue: selectedOption.text, fieldLabel: selectedOption.text })
                                                    }
                                                />
                                                <DefaultButton text="Annulla"
                                                    style={{ "marginRight": "5px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        handleShowEditOutboundField("Mittente", false)
                                                    }}
                                                />
                                                <PrimaryButton
                                                    id="btnUpdateOutboundMittente"
                                                    text="Salva"
                                                    style={{ minWidth: "100px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        fetchUpdateOutbound(selectedRowDetails).then(() => {
                                                            selectedRowDetails.mittente = updateData.fieldLabel;
                                                        });
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/*MODALITA*/}
                                    <div className="DetailsRow d-flex flex-wrap">
                                        <div className={(showEditModalita) ? "d-flex justify-content-between col-12" : "d-flex justify-content-between col-12"}>
                                            <div>
                                                <Icon iconName="Transition" /> <b>Spedizione<sup className="text-danger">*</sup> :</b>{" "}
                                                <span id="ValueLabelModalita">{selectedRowDetails.modalita}</span>
                                            </div>
                                            {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditModalita) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Modalita", true)}>[modifica]</a>}
                                        </div>
                                        <div className={(showEditModalita) ? "col-12 d-inline" : "col-12 d-none"}>
                                            <div className={(showEditModalita) ? "d-inline" : "d-none"}>
                                                <Dropdown
                                                    id="EditModalita"
                                                    name="Modalita"
                                                    placeholder="Scegli Modalita"
                                                    options={optionsModalita}
                                                    onChange={(item, selectedOption) =>
                                                        setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Modalita", fieldValue: selectedOption.key, fieldLabel: selectedOption.text })
                                                    }
                                                />
                                                <DefaultButton text="Annulla"
                                                    style={{ "marginRight": "5px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        handleShowEditOutboundField("Modalita", false)
                                                    }}
                                                />
                                                <PrimaryButton
                                                    id="btnUpdateOutboundModalita"
                                                    text="Salva"
                                                    style={{ minWidth: "100px", marginTop: "5px" }}
                                                    onClick={() => {
                                                        fetchUpdateOutbound(selectedRowDetails).then(() => {
                                                            selectedRowDetails.modalita = updateData.fieldLabel;
                                                            selectedRowDetails.idModalita = updateData.fieldValue;
                                                        });
                                                    }}
                                                    allowDisabledFocus
                                                />
                                            </div>
                                        </div>
                                    </div>

                                  
                                </div>
                                {(selectedRowDetails.anagrafica && selectedRowDetails.anagrafica != null) ?
                                    <div className="col-sm-12 col-md-6">
                                        <h6>Anagrafica</h6>
                                        <hr />

                                        {/*COGNOME*/}
                                        <div className="DetailsRow d-flex flex-wrap">
                                            <div className={(showEditCognome) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                                <div>
                                                    <b>Cognome:</b> <span id="ValueLabelCognome">{selectedRowDetails.anagrafica.cognome}</span>
                                                </div>
                                                {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditCognome) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Cognome", true)}>[modifica]</a>}
                                            </div>
                                            <div className={(showEditCognome) ? "col-12 d-inline mt-2" : "col-12 d-none mt-2"}>
                                                <div className={(showEditCognome) ? "d-inline" : "d-none"}>
                                                    <TextField
                                                        id="Cognome"
                                                        defaultValue={selectedRowDetails.anagrafica.cognome}
                                                        onChange={(event, value) => {
                                                            setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Cognome", fieldValue: value, fieldLabel: value })
                                                        }}
                                                    />
                                                    <DefaultButton text="Annulla"
                                                        style={{ "marginRight": "5px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            handleShowEditOutboundField("Cognome", false);
                                                        }}
                                                    />
                                                    <PrimaryButton
                                                        id="btnUpdateOutboundCognome"
                                                        text="Salva"
                                                        style={{ minWidth: "100px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.anagrafica.cognome = updateData.fieldLabel; })
                                                        }}
                                                        allowDisabledFocus
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*NOME*/}
                                        <div className="DetailsRow d-flex flex-wrap">
                                            <div className={(showEditNome) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                                <div>
                                                    <b>Nome:</b> <span id="ValueLabelNome">{selectedRowDetails.anagrafica.nome}</span>
                                                </div>
                                                {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditNome) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Nome", true)}>[modifica]</a>}
                                            </div>
                                            <div className={(showEditNome) ? "col-12 d-inline mt-2" : "col-12 d-none mt-2"}>
                                                <div className={(showEditNome) ? "d-inline" : "d-none"}>
                                                    <TextField
                                                        id="Nome"
                                                        defaultValue={selectedRowDetails.anagrafica.nome}
                                                        onChange={(event, value) => {
                                                            setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Nome", fieldValue: value, fieldLabel: value })
                                                        }}
                                                    />
                                                    <DefaultButton text="Annulla"
                                                        style={{ "marginRight": "5px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            handleShowEditOutboundField("Nome", false);
                                                        }}
                                                    />
                                                    <PrimaryButton
                                                        id="btnUpdateOutboundNome"
                                                        text="Salva"
                                                        style={{ minWidth: "100px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.anagrafica.nome = updateData.fieldLabel; })
                                                        }}
                                                        allowDisabledFocus
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*INDIRIZZO*/}
                                        <div className="DetailsRow d-flex flex-wrap">
                                            <div className={(showEditIndirizzo) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                                <div>
                                                    <b>Indirizzo:</b> <span id="ValueLabelIndirizzo">{selectedRowDetails.anagrafica.indirizzo}</span>
                                                </div>
                                                {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditIndirizzo) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Indirizzo", true)}>[modifica]</a>}
                                            </div>
                                            <div className={(showEditIndirizzo) ? "col-12 d-inline mt-2" : "col-12 d-none mt-2"}>
                                                <div className={(showEditIndirizzo) ? "d-inline" : "d-none"}>
                                                    <TextField
                                                        id="Indirizzo"
                                                        defaultValue={selectedRowDetails.anagrafica.indirizzo}
                                                        onChange={(event, value) => {
                                                            setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Indirizzo", fieldValue: value, fieldLabel: value })
                                                        }}
                                                    />
                                                    <DefaultButton text="Annulla"
                                                        style={{ "marginRight": "5px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            handleShowEditOutboundField("Indirizzo", false);
                                                        }}
                                                    />
                                                    <PrimaryButton
                                                        id="btnUpdateOutboundIndirizzo"
                                                        text="Salva"
                                                        style={{ minWidth: "100px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.anagrafica.indirizzo = updateData.fieldLabel; })
                                                        }}
                                                        allowDisabledFocus
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*CIVICO*/}
                                        <div className="DetailsRow d-flex flex-wrap">
                                            <div className={(showEditCivico) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                                <div>
                                                    <b>Civico:</b> <span id="ValueLabelCivico">{selectedRowDetails.anagrafica.civico}</span>
                                                </div>
                                                {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditCivico) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Civico", true)}>[modifica]</a>}
                                            </div>
                                            <div className={(showEditCivico) ? "col-12 d-inline mt-2" : "col-12 d-none mt-2"}>
                                                <div className={(showEditCivico) ? "d-inline" : "d-none"}>
                                                    <TextField
                                                        id="Civico"
                                                        defaultValue={selectedRowDetails.anagrafica.civico}
                                                        onChange={(event, value) => {
                                                            setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Civico", fieldValue: value, fieldLabel: value })
                                                        }}
                                                    />
                                                    <DefaultButton text="Annulla"
                                                        style={{ "marginRight": "5px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            handleShowEditOutboundField("Civico", false);
                                                        }}
                                                    />
                                                    <PrimaryButton
                                                        id="btnUpdateOutboundCivico"
                                                        text="Salva"
                                                        style={{ minWidth: "100px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.anagrafica.civico = updateData.fieldLabel; })
                                                        }}
                                                        allowDisabledFocus
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*CAP*/}
                                        <div className="DetailsRow d-flex flex-wrap">
                                            <div className={(showEditCap) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                                <div>
                                                    <b>CAP:</b> <span id="ValueLabelCap">{selectedRowDetails.anagrafica.cap}</span>
                                                </div>
                                                {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditCap) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Cap", true)}>[modifica]</a>}
                                            </div>
                                            <div className={(showEditCap) ? "col-12 d-inline mt-2" : "col-12 d-none mt-2"}>
                                                <div className={(showEditCap) ? "d-inline" : "d-none"}>
                                                    <TextField
                                                        id="Cap"
                                                        defaultValue={selectedRowDetails.anagrafica.cap}
                                                        onChange={(event, value) => {
                                                            setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Cap", fieldValue: value, fieldLabel: value })
                                                        }}
                                                    />
                                                    <DefaultButton text="Annulla"
                                                        style={{ "marginRight": "5px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            handleShowEditOutboundField("Cap", false);
                                                        }}
                                                    />
                                                    <PrimaryButton
                                                        id="btnUpdateOutboundCap"
                                                        text="Salva"
                                                        style={{ minWidth: "100px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.anagrafica.cap = updateData.fieldLabel; })
                                                        }}
                                                        allowDisabledFocus
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*LOCALITA*/}
                                        <div className="DetailsRow d-flex flex-wrap">
                                            <div className={(showEditLocalita) ? "d-flex justify-content-between col-12" : "d-flex justify-content-between col-12"}>
                                                <div>
                                                    <b>Localit&agrave;:</b> <span id="ValueLabelLocalita">{selectedRowDetails.anagrafica.localita}</span>
                                                </div>
                                                {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditLocalita) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Localita", true)}>[modifica]</a>}
                                            </div>
                                            <div className={(showEditLocalita) ? "col-12 d-inline" : "col-12 d-none"}>
                                                <div className={(showEditLocalita) ? "d-inline" : "d-none"}>
                                                    {!localitaIsText ?
                                                        <Dropdown
                                                            id="EditLocalita"
                                                            name="Localita"
                                                            placeholder="Scegli Localita"
                                                            options={optionsLocalita}
                                                            onChange={(item, selectedOption) =>
                                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Localita", fieldValue: selectedOption.key, fieldLabel: selectedOption.text })
                                                            }
                                                        />
                                                        :
                                                        <TextField
                                                            id="Localita"
                                                            name="Localita"
                                                            placeholder="Scegli Localita"
                                                            defaultValue={selectedRowDetails.localita}
                                                            onChange={(event, value) => {
                                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Localita", fieldValue: value, fieldLabel: value })
                                                            }}
                                                       
                                                        />
                                                        }
                                                    <DefaultButton text="Annulla"
                                                        style={{ "marginRight": "5px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            handleShowEditOutboundField("Localita", false)
                                                        }}
                                                    />
                                                    <PrimaryButton
                                                        id="btnUpdateOutboundLocalita"
                                                        text="Salva"
                                                        style={{ minWidth: "100px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            fetchUpdateOutbound(selectedRowDetails).then(() => {
                                                                selectedRowDetails.anagrafica.localita = updateData.fieldLabel;
                                                            });
                                                        }}
                                                        allowDisabledFocus
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*PROVINCIA*/}
                                        <div className="DetailsRow d-flex flex-wrap">
                                            <div className={(showEditProvincia) ? "d-flex justify-content-between col-12" : "d-flex justify-content-between col-12"}>
                                                <div>
                                                    <b>Provincia:</b> <span id="ValueLabelProvincia">{selectedRowDetails.anagrafica.provincia}</span>
                                                </div>
                                                {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditProvincia) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Provincia", true)}>[modifica]</a>}
                                            </div>
                                            <div className={(showEditProvincia) ? "col-12 d-inline" : "col-12 d-none"}>
                                                <div className={(showEditProvincia) ? "d-inline" : "d-none"}>
                                                    <Dropdown
                                                        id="EditProvincia"
                                                        name="Provincia"
                                                        placeholder="Scegli Provincia"
                                                        options={optionsProvincia}
                                                        onChange={(item, selectedOption) =>
                                                            setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Provincia", fieldValue: selectedOption.key, fieldLabel: selectedOption.text })
                                                        }
                                                    />
                                                    <DefaultButton text="Annulla"
                                                        style={{ "marginRight": "5px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            handleShowEditOutboundField("Provincia", false)
                                                        }}
                                                    />
                                                    <PrimaryButton
                                                        id="btnUpdateOutboundProvincia"
                                                        text="Salva"
                                                        style={{ minWidth: "100px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            fetchUpdateOutbound(selectedRowDetails).then(() => {
                                                                selectedRowDetails.anagrafica.provincia = updateData.fieldLabel;
                                                                selectedRowDetails.anagrafica.localita = "";
                                                            });
                                                        }}
                                                        allowDisabledFocus
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/*COD RACCOMANDATA*/}
                                        <div className="DetailsRow d-flex flex-wrap">
                                            <div className={(showEditCodiceRaccomandata) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                                <div>
                                                    <b>Cod. Raccomandata:</b> <span id="ValueLabelCodiceRaccomandata">{selectedRowDetails.anagrafica.codiceRaccomandata}</span>
                                                </div>
                                                {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditCodiceRaccomandata) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("CodiceRaccomandata", true)}>[modifica]</a>}
                                            </div>
                                            <div className={(showEditCodiceRaccomandata) ? "col-12 d-inline mt-2" : "col-12 d-none mt-2"}>
                                                <div className={(showEditCodiceRaccomandata) ? "d-inline" : "d-none"}>
                                                    <TextField
                                                        id="CodiceRaccomandata"
                                                        defaultValue={selectedRowDetails.anagrafica.codiceRaccomandata}
                                                        onChange={(event, value) => {
                                                            setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "CodiceRaccomandata", fieldValue: value, fieldLabel: value })
                                                        }}
                                                    />
                                                    <DefaultButton text="Annulla"
                                                        style={{ "marginRight": "5px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            handleShowEditOutboundField("CodiceRaccomandata", false);
                                                        }}
                                                    />
                                                    <PrimaryButton
                                                        id="btnUpdateOutboundCodiceRaccomandata"
                                                        text="Salva"
                                                        style={{ minWidth: "100px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.anagrafica.codiceRaccomandata = updateData.fieldLabel; })
                                                        }}
                                                        allowDisabledFocus
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                            {/*NOTE*/}
                            {(selectedRowDetails.anagrafica && selectedRowDetails.anagrafica != null) ?
                                <div className="row">
                                    <div className="col-12">
                                        <div className="DetailsRow d-flex flex-wrap">
                                            <div className={(showEditNote) ? "d-flex justify-content-between col-12" : "d-flex justify-content-between col-12"}>
                                                <div>
                                                    <Icon iconName="DietPlanNotebook" /> <b>Note:</b>{" "}
                                                    <span id="ValueLabelNote">{selectedRowDetails.anagrafica.note}</span>
                                                </div>
                                                {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditNote) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditOutboundField("Note", true)}>[modifica]</a>}
                                            </div>
                                            <div className={(showEditNote) ? "col-12 d-inline" : "col-12 d-none"}>
                                                <div className={(showEditNote) ? "d-inline" : "d-none"}>
                                                    <TextField
                                                        id="Note"
                                                        multiline={true}
                                                        defaultValue={selectedRowDetails.anagrafica.note}
                                                        onChange={(event, value) => {
                                                            setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Note", fieldValue: value, fieldLabel: value })
                                                        }}
                                                    />
                                                    <DefaultButton text="Annulla"
                                                        style={{ "marginRight": "5px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            handleShowEditOutboundField("Note", false)
                                                        }}
                                                    />
                                                    <PrimaryButton
                                                        id="btnUpdateOutboundNote"
                                                        text="Salva"
                                                        style={{ minWidth: "100px", marginTop: "5px" }}
                                                        onClick={() => {
                                                            fetchUpdateOutbound(selectedRowDetails).then(() => { selectedRowDetails.anagrafica.note = updateData.fieldLabel; });
                                                        }}
                                                        allowDisabledFocus
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}



                            {(!protocolloState.roleIsReadOnly) ?
                                <div className="DetailsRow">
                                    <div className="col-12 text-center">
                                        <PrimaryButton
                                            className={(showConfirmDelete) ? "d-none" : "d-inline"}
                                            id="btnDeleteOutbound"
                                            text="Elimina questo protocollo"
                                            style={{ minWidth: "100px", marginTop: "5px", backgroundColor: 'red', borderColor: 'red' }}
                                            onClick={() => {
                                                setShowConfirmDelete(true);
                                            }}
                                            allowDisabledFocus
                                        />
                                        <div id="DeleteConfirmation" className={(showConfirmDelete) ? "d-inline" : "d-none"}>
                                            <h6>Sei sicuro di voler eliminare questo protocollo?</h6>
                                            <DefaultButton
                                                id="btnConfirmDeleteOutbound"
                                                text="Annulla eliminazione"
                                                style={{ minWidth: "100px", marginTop: "5px", marginRight: "5px" }}
                                                onClick={() => {
                                                    setShowConfirmDelete(false);
                                                    setErrorUpdatingMessage("");
                                                }}
                                                allowDisabledFocus={false}
                                                disabled={((isDoingDelete) ? true : false)}
                                            />
                                            <PrimaryButton
                                                id="btnCancelDeleteOutbound"
                                                text="Conferma eliminazione"
                                                style={{ minWidth: "100px", marginTop: "5px", backgroundColor: 'red', borderColor: 'red' }}
                                                onClick={() => {
                                                    setErrorUpdatingMessage("");
                                                    deleteProtocollo(selectedRowDetails.idProtocollo).then((res) => {
                                                        console.log(res)
                                                        if (res === true) {
                                                            setShowConfirmDelete(false);
                                                        }
                                                        else if (res === false) {

                                                        }
                                                        setIsDoingDelete(false);
                                                    });
                                                }}
                                                allowDisabledFocus={false}
                                                disabled={((isDoingDelete) ? true : false)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            <div className={(errorUpdatingMessage != "") ? "DetailsRow d-inline" : "DetailsRow d-none"}>
                                <div className="alert alert-danger" role="alert" id="errorUpdatingMessage">
                                    {errorUpdatingMessage}
                                </div>
                            </div>


                        </div>
                    </Modal>
                </Stack>
            </Fabric>
        </div >
}
export default Outbound;