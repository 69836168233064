import React, { useEffect, useState, useContext } from 'react';
import ProtocolloContext from '../ProtocolloContext';
import { adalApiFetch, requestOptions } from '../adalConfig';
import { Fabric, Stack, Pivot, PivotItem, Label } from 'office-ui-fabric-react/lib';
import { DayPickerStrings } from './DayPickerStrings';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import FormInbound from './FormInbound';
import FormOutbound from './FormOutbound'
import Loading from './Loading';

const Home = (props) => {

    initializeIcons(undefined, { disableWarnings: true });
    const [protocolloState, setProtocolloState] = useContext(ProtocolloContext);
    const [isLoading, setIsLoading] = useState(false);
    const [optionsDocumento, setOptionsDocumento] = useState([]);
    const [optionsMezzo, setOptionsMezzo] = useState([]);
    const [optionsArea, setOptionsArea] = useState([]);
    const [optionsRicezione, setOptionsRicezione] = useState([]);
    const [optionsMittente, setOptionsMittente] = useState([]);
    const [optionsModalita, setOptionsModalita] = useState([]);
    const [optionsProvincia, setOptionsProvincia] = useState([]);
    const stackTokens = { childrenGap: 10 };
    const labelStyles = { root: { marginTop: 10 }, };
    const LoadOptionsList = async (optionListName) => {
        var cachedOptionList = sessionStorage.getItem("HOME_LIST_" + optionListName);
        if (cachedOptionList) {
            if (optionListName === "Area") {
                setOptionsArea(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Documento") {
                setOptionsDocumento(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Ricezione") {
                setOptionsRicezione(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Mezzo") {
                setOptionsMezzo(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Mittente") {
                setOptionsMittente(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Modalita") {
                setOptionsModalita(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Provincia") {
                setOptionsProvincia(JSON.parse(cachedOptionList));
            }
        }
        else {

            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/optionsList?context=${protocolloState.context}&optionListName=${optionListName}`;
            var optionsGet = requestOptions;
            optionsGet.method = 'get';
            optionsGet.body = null;
            await adalApiFetch(fetch, url, optionsGet)
                .then((response) => response.json())
                .then((data) => { //TODO: if(data) if is populated
                    if (optionListName === "Area") {
                        let newData = [...data];
                        newData.unshift({ text: "Nessuna", key: "", });
                        setOptionsArea(newData);
                        sessionStorage.setItem("HOME_LIST_" + optionListName, JSON.stringify(newData));
                    }
                    if (optionListName === "Documento") {
                        setOptionsDocumento(data);
                        sessionStorage.setItem("HOME_LIST_" + optionListName, JSON.stringify(data));
                    }
                    if (optionListName === "Ricezione") {
                        let newData = [...data];
                        newData.unshift({ text: "Non specificato", key: "", });
                        setOptionsRicezione(newData);
                        sessionStorage.setItem("HOME_LIST_" + optionListName, JSON.stringify(newData));
                    }
                    if (optionListName === "Mezzo") {
                        let newData = [...data];
                        newData.unshift({ text: "Non specificato", key: "", });
                        setOptionsMezzo(newData);
                        sessionStorage.setItem("HOME_LIST_" + optionListName, JSON.stringify(newData));
                    }
                    if (optionListName === "Mittente") {
                        setOptionsMittente(data);
                        sessionStorage.setItem("HOME_LIST_" + optionListName, JSON.stringify(data));
                    }
                    if (optionListName === "Modalita") {
                        setOptionsModalita(data);
                        sessionStorage.setItem("HOME_LIST_" + optionListName, JSON.stringify(data));
                    }
                    if (optionListName === "Provincia") {
                        setOptionsProvincia(data);
                        sessionStorage.setItem("HOME_LIST_" + optionListName, JSON.stringify(data));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    useEffect(() => {

        async function fetchOptionsList() {
            setIsLoading(true);
            await LoadOptionsList("Mezzo");
            await LoadOptionsList("Documento");
            await LoadOptionsList("Area");
            await LoadOptionsList("Ricezione");
            await LoadOptionsList("Mittente");
            await LoadOptionsList("Modalita");
            await LoadOptionsList("Provincia");
            setIsLoading(false);
        }
        fetchOptionsList();
        //appInsights.trackPageView({ name: 'Timbra' });
    }, []);
    return (isLoading) ?
        <Loading message="Attendere..." />
        :
        <div>
            <h1><div className="ms-font-su ms-fontColor-themePrimary">Registrazione</div></h1>
            <p>Scegli se registrare un protocollo in <b>ingresso</b> o in <b>uscita</b></p>
            <Fabric>
                <Stack tokens={stackTokens}>
                    <Pivot aria-label="Basic Pivot Example">
                        <PivotItem headerText="Ingresso" itemIcon="PageCheckedOut" >
                            <Label styles={labelStyles}>
                                <FormInbound dayPickerStrings={DayPickerStrings} optionsArea={optionsArea} optionsDocumento={optionsDocumento} optionsMezzo={optionsMezzo} optionsRicezione={optionsRicezione} />
                            </Label>
                        </PivotItem>
                        <PivotItem headerText="Uscita" itemIcon="PageCheckedin">
                            <Label styles={labelStyles}>
                                <FormOutbound dayPickerStrings={DayPickerStrings} optionsArea={optionsArea} optionsMittente={optionsMittente} optionsModalita={optionsModalita} optionsProvincia={optionsProvincia} />
                            </Label>
                        </PivotItem>
                    </Pivot>
                </Stack>
            </Fabric>
        </div>
}
export default Home;