import React, { useState, useContext } from 'react';
import ProtocolloContext from '../ProtocolloContext';
import { adalApiFetch, requestOptions } from '../adalConfig';
import moment from 'moment';
import 'moment-timezone';
import { DatePicker, TextField, Dropdown, DefaultButton, ComboBox } from 'office-ui-fabric-react/lib';

const FormOutbound = (props) => {

    const [protocolloState, setProtocolloState] = useContext(ProtocolloContext);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState({
        Context: protocolloState.context,
        DataRichiesta: "",
        DataInvio: "",
        Mittente: "",
        Area: "",
        Oggetto: "",
        Modalita: "",
        Titolo: "",
        RichiestoDa: "",
        Cognome: "",
        Nome: "",
        Indirizzo: "",
        Cap: "",
        Civico: "",
        Provincia: "",
        Localita: "",
        Note: "",
    });
    const [optionsOggetto, setOptionsOggetto] = useState([]);
    const [optionsLocalita, setOptionsLocalita] = useState([]);
    const [disableOggetto, setDisableOggetto] = useState(true);
    const [disableLocalita, setDisableLocalita] = useState(true);
    const [initialDataRichiesta, setInitialDataRichiesta] = useState("");
    const [initialDataInvio, setInitialDataInvio] = useState("");
    const [showConfirm, setShowConfirm] = useState(false);
    const [showAreaError, setShowAreaError] = useState(false);
    const [showMittenteError, setShowMittenteError] = useState(false);
    const [showModalitaError, setShowModalitaError] = useState(false);
    const [showOggettoError, setShowOggettoError] = useState(false);
    const [showProvinciaError, setShowProvinciaError] = useState(false);
    const [showLocalitaError, setShowLocalitaError] = useState(false);
    const [showDataRichiestaError, setShowDataRichiestaError] = useState(false);
    const [showDataInvioError, setShowDataInvioError] = useState(false);
    const [idProtocolloCreated, setIdProtocolloCreated] = useState(null);
    const [localitaIsText, setLocalitaIsText] = useState(false);
    const [selectedMittente, setSelectedMittente] = useState("");
    const [selectedArea, setSelectedArea] = useState("");
    const [selectedSpedizione, setSelectedSpedizione] = useState("");
    const [selectedOggetto, setSelectedOggetto] = useState("");
    const [selectedProvincia, setSelectedProvincia] = useState("");
    const [selectedLocalita, setSelectedLocalita] = useState("");

    const loadOggetto = async (selectedObj) => {
        var idArea = selectedObj.key;
        var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/optionsList?context=${protocolloState.context}&optionListName=Oggetto&idArea=${idArea}`;
        var optionsGet = requestOptions;
        optionsGet.method = 'get';
        optionsGet.body = null;
        await adalApiFetch(fetch, url, optionsGet)
            .then((response) => response.json())
            .then((data) => {
                if (data.length > 0) {
                    setDisableOggetto(false)
                }
                else {
                    setDisableOggetto(true)
                }

                setOptionsOggetto(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const loadLocalita = async (selectedObj) => {
        var provincia = selectedObj.key;
        if (provincia === "EE") {
            setLocalitaIsText(true);
            setDisableLocalita(false)
        } else {
            setLocalitaIsText(false);
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/optionsList?context=${protocolloState.context}&optionListName=Localita&provincia=${provincia}`;
            var optionsGet = requestOptions;
            optionsGet.method = 'get';
            optionsGet.body = null;
            await adalApiFetch(fetch, url, optionsGet)
                .then((response) => response.json())
                .then((data) => {
                    if (data.length > 0) {
                        setDisableLocalita(false)
                    }
                    else {
                        setDisableLocalita(true)
                    }

                    setOptionsLocalita(data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }
    const updateFormData = (field, value) => {
        var newData = { ...formData };
        if (field === "Area")
            setShowAreaError(false);
        if (field === "Oggetto")
            setShowOggettoError(false);
        if (field === "Provincia")
            setShowProvinciaError(false);
        if (field === "Localita")
            setShowLocalitaError(false);
        if (field === "Modalita")
            setShowModalitaError(false);
        if (field === "Mittente")
            setShowMittenteError(false);
        if (field === "DataRichiesta" && value !== "" && value !== null) {
            setShowDataRichiestaError(false);
            setInitialDataRichiesta(value);
            newData[field] = moment(value).format("YYYY-MM-DD");
        }
        else if (field === "DataInvio" && value !== "" && value !== null) {
            setShowDataInvioError(false);
            setInitialDataInvio(value);
            newData[field] = moment(value).format("YYYY-MM-DD");
        }
        else {
            newData[field] = value;
        }
        setFormData(newData);
    }
    const registerOutbound = async (event) => {
        setIsLoading(true);
        event.preventDefault();
        var blockSend = false;
        if (formData.Context === "" || formData.Context === undefined) {
            formData.Context = protocolloState.context;
        }
        if (formData.DataRichiesta === "") {
            setShowDataRichiestaError(true);
            blockSend = (!blockSend) ? true : blockSend;
        }
        if (formData.Area === "" || formData.Area === undefined) {
            setShowAreaError(true);
            blockSend = (!blockSend) ? true : blockSend;
        }
        if (formData.Mittente === "") {
            setShowMittenteError(true);
            blockSend = (!blockSend) ? true : blockSend;
        }
        if (formData.Modalita === "") {
            setShowModalitaError(true);
            blockSend = (!blockSend) ? true : blockSend;
        }
        if (isRequired() && (formData.Provincia === "" || formData.Provincia === undefined)) {
            setShowProvinciaError(true);
            blockSend = (!blockSend) ? true : blockSend;
        }
        if (isRequired() && (formData.Localita === "" || formData.Localita === undefined)) {
            setShowLocalitaError(true);
            blockSend = (!blockSend) ? true : blockSend;
        }
        if (blockSend) {
            setIsLoading(false);
            return false;
        }
        else {
            setIsLoading(true);
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/protocollooutbound`;
            var optionsPost = requestOptions;
            optionsPost.method = 'post';
            optionsPost.body = JSON.stringify(formData);
            await adalApiFetch(fetch, url, optionsPost)
                .then((response) => response.json())
                .then((data) => {
                    if (data) {
                        if (data.idProtocollo > 0) {
                            setIdProtocolloCreated(data.idProtocollo);
                            setShowConfirm(true);
                            setFormData({
                                Context: protocolloState.context,
                                DataRichiesta: "",
                                DataInvio: "",
                                Mittente: "",
                                Area: "",
                                Oggetto: "",
                                Modalita: "",
                                Titolo: "",
                                RichiestoDa: "",
                                Cognome: "",
                                Nome: "",
                                Indirizzo: "",
                                Cap: "",
                                Civico: "",
                                Provincia: "",
                                Localita: "",
                                Note: "",
                            });
                            setInitialDataRichiesta("");
                            setInitialDataInvio("");
                        }
                    }
                    else {

                    }
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
        }
    }
    const backToForm = () => {
        setInitialDataRichiesta("");
        setInitialDataInvio("");
        setLocalitaIsText(false);
        setShowConfirm(false);
    }

    const isRequired = () => {
        return formData.Modalita === 3 || formData.Modalita === 4;
    }

    if (showConfirm) {
        return (
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-9 text-center pt-4">
                    <div className="ms-font-xxl">Protocollo creato <span className="ms-fontColor-themePrimary">#{idProtocolloCreated}</span>.</div>
                    <DefaultButton className="mt-4" text="Indietro" onClick={() => backToForm()} />
                </div>
            </div>
        );
    }
    else {
        return (
            <form id="inboundForm" method="POST" onSubmit={registerOutbound}>
                <div className="row mb-3">
                    <div className="col-12 ms-font-l ms-fontColor-themePrimary">
                        Informazioni per il Protocollo
                </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <DatePicker
                            id="DataRichiesta"
                            value={initialDataRichiesta}
                            strings={props.dayPickerStrings}
                            firstWeekOfYear={1}
                            firstDayOfWeek={1}
                            showMonthPickerAsOverlay={true}
                            disableAutoFocus={true}
                            placeholder="Select a date..."
                            ariaLabel="Data Richiesta"
                            label="Data Richiesta"
                            showGoToToday={false}
                            showWeekNumbers={false}
                            textField={(showDataRichiestaError) ? { errorMessage: "Inserire Data Richiesta" } : null}
                            formatDate={(date) => { return (moment(date).format("DD/MM/YYYY")); }}
                            onSelectDate={(date) =>
                                updateFormData("DataRichiesta", date)
                            }
                            isRequired={true}
                        />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <DatePicker
                            id="DataInvio"
                            value={initialDataInvio}
                            strings={props.dayPickerStrings}
                            firstWeekOfYear={1}
                            firstDayOfWeek={1}
                            showMonthPickerAsOverlay={true}
                            disableAutoFocus={true}
                            placeholder="Select a date..."
                            ariaLabel="Data Invio"
                            label="Data Invio"
                            showGoToToday={false}
                            showWeekNumbers={false}
                            textField={(showDataInvioError) ? { errorMessage: "Inserire Data Invio" } : null}
                            formatDate={(date) => { return (moment(date).format("DD/MM/YYYY")); }}
                            onSelectDate={(date) =>
                                updateFormData("DataInvio", date)
                            }
                        />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-3">
                        {/*<Dropdown*/}
                        {/*    placeholder="Scegli Mittente"*/}
                        {/*    label="Mittente"*/}
                        {/*    options={props.optionsMittente}*/}
                        {/*    onChange={(event, item) => { updateFormData("Mittente", item.key) }}*/}
                        {/*    errorMessage={showMittenteError ? 'Compila Mittente' : undefined}*/}
                        {/*    required*/}
                        {/*/>*/}
                        <ComboBox
                            placeholder="Scegli Mittente"
                            label="Mittente"
                            options={props.optionsMittente}
                            selectedKey={selectedMittente}
                            onChange={(event, option, index) => {
                                setSelectedMittente(option.key);
                                updateFormData("Mittente", option.key);
                            }}
                            allowFreeform={true}
                            autoComplete="on"
                            errorMessage={showMittenteError ? 'Compila Mittente' : undefined}
                            required
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-4 col-lg-3">
                        {/*<Dropdown*/}
                        {/*    id="Area"*/}
                        {/*    placeholder="Scegli Area"*/}
                        {/*    label="Area"*/}
                        {/*    options={props.optionsArea.filter(a => a.key !== 1 && a.key !== 2)}*/}
                        {/*    onChange={(event, item) => {*/}
                        {/*        loadOggetto(item);*/}
                        {/*        updateFormData("Area", item.key);*/}
                        {/*    }}*/}
                        {/*    errorMessage={showAreaError ? 'Compila Area' : undefined}*/}
                        {/*    required*/}
                        {/*/>*/}
                        <ComboBox
                            id="Area"
                            placeholder="Scegli Area"
                            label="Area"
                            selectedKey={selectedArea}
                            options={props.optionsArea.filter(a => a.key !== 1 && a.key !== 2)}
                            onChange={(event, option, index) => {
                                loadOggetto(option);
                                setSelectedArea(option.key);
                                updateFormData("Area", option.key);
                            }}
                            allowFreeform={true}
                            autoComplete="on"
                            errorMessage={showAreaError ? 'Compila Area' : undefined}
                            required
                        />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        {/*<Dropdown*/}
                        {/*    placeholder="Scegli Spedizione"*/}
                        {/*    label="Spedizione"*/}
                        {/*    options={props.optionsModalita}*/}
                        {/*    onChange={(event, item) => { updateFormData("Modalita", item.key) }}*/}
                        {/*    errorMessage={showModalitaError ? 'Compila Spedizione' : undefined}*/}
                        {/*    required*/}
                        {/*/>*/}
                        <ComboBox
                            placeholder="Scegli Spedizione"
                            label="Spedizione"
                            options={props.optionsModalita}
                            selectedKey={selectedSpedizione}
                            onChange={(event, option, index) => {
                                setSelectedSpedizione(option.key);
                                updateFormData("Modalita", option.key);
                            }}
                            errorMessage={showModalitaError ? 'Compila Spedizione' : undefined}
                            required
                            allowFreeform={true}
                            autoComplete="on"
                        />
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-3">
                        <TextField label="Titolo invio" onChange={(event, value) => { updateFormData("Titolo", value) }} required />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-8 col-lg-6">
                        {/*<Dropdown*/}
                        {/*    placeholder="Scegli oggetto"*/}
                        {/*    label="Oggetto"*/}
                        {/*    options={optionsOggetto}*/}
                        {/*    disabled={disableOggetto}*/}
                        {/*    onChange={(event, item) => { updateFormData("Oggetto", item.key) }}*/}
                        {/*    errorMessage={showOggettoError ? 'Compila Oggetto' : undefined}*/}
                        {/*/>*/}
                        <ComboBox
                            placeholder="Scegli oggetto"
                            label="Oggetto"
                            options={optionsOggetto}
                            disabled={disableOggetto}
                            selectedKey={selectedOggetto}
                            onChange={(event, option, index) => {
                                setSelectedOggetto(option.key);
                                updateFormData("Oggetto", option.key);
                            }}
                            errorMessage={showOggettoError ? 'Compila Oggetto' : undefined}
                            allowFreeform={true}
                            autoComplete="on"
                        />
                    </div>
                    <div className="col-sm-12 col-md-4 col-lg-3">
                        <TextField label="Richiesto da" onChange={(event, value) => { updateFormData("RichiestoDa", value) }} required />
                    </div>
                </div>
                <hr />
                <div className="row mb-3">
                    <div className="col-12 ms-font-l ms-fontColor-themePrimary">
                        Anagrafica
                </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <TextField label="Cognome" onChange={(event, value) => { updateFormData("Cognome", value) }} required={isRequired()} />
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <TextField label="Nome" onChange={(event, value) => { updateFormData("Nome", value) }} required={isRequired()} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        <TextField label="Indirizzo" onChange={(event, value) => { updateFormData("Indirizzo", value) }} required={isRequired()} />
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <TextField label="Civico" onChange={(event, value) => { updateFormData("Civico", value) }} required={isRequired()} />
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <TextField label="Cap" onChange={(event, value) => { updateFormData("Cap", value) }} required={isRequired()} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-6">
                        {/*<Dropdown*/}
                        {/*    placeholder="Scegli provincia"*/}
                        {/*    label="Provincia"*/}
                        {/*    options={props.optionsProvincia}*/}
                        {/*    onChange={(event, item) => {*/}
                        {/*        loadLocalita(item);*/}
                        {/*        updateFormData("Provincia", item.key);*/}
                        {/*    }}                            */}
                        {/*    errorMessage={showProvinciaError ? 'Compila Provincia' : undefined}*/}
                        {/*    required={isRequired()}*/}
                        {/*/>*/}
                        <ComboBox
                            placeholder="Scegli provincia"
                            label="Provincia"
                            options={props.optionsProvincia}
                            selectedKey={selectedProvincia}
                            onChange={(event, option,index) => {
                                loadLocalita(option);
                                setSelectedProvincia(option.key);
                                updateFormData("Provincia", option.key);
                            }}
                            errorMessage={showProvinciaError ? 'Compila Provincia' : undefined}
                            required={isRequired()}
                            allowFreeform={true}
                            autoComplete="on"
                        />
                    </div>
                    <div className="col-sm-12 col-md-6">
                        {!localitaIsText ?
                            //<Dropdown
                            //    placeholder="Scegli località"
                            //    label="Località"
                            //    options={optionsLocalita}
                            //    disabled={disableLocalita}
                            //    onChange={(event, item) => { updateFormData("Localita", item.key) }}
                            //    errorMessage={showLocalitaError ? 'Compila Localita' : undefined}
                            //    required={isRequired()}
                            ///>
                            <ComboBox
                                placeholder="Scegli località"
                                label="Località"
                                options={optionsLocalita}
                                selectedKey={selectedLocalita}
                                disabled={disableLocalita}
                                onChange={(event, option, index) => {
                                    debugger;
                                    setSelectedLocalita(option.key);
                                    updateFormData("Localita", option.key);
                                }}
                                errorMessage={showLocalitaError ? 'Compila Localita' : undefined}
                                required={isRequired()}
                                allowFreeform={true}
                                autoComplete="on"
                            />
                            :
                            <TextField label="località"
                                disabled={disableLocalita}
                                errorMessage={showLocalitaError ? 'Compila Localita' : undefined}
                                onChange={(event, value) => { updateFormData("Localita", value) }}
                                required={isRequired()} />
                        }
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <TextField label="Note" onChange={(event, value) => {updateFormData("Note", value)}} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-9 text-right pt-4">
                        <DefaultButton type="submit" text={(isLoading) ? "Attendi..." : "Registra"} disabled={isLoading} allowDisabledFocus />
                    </div>
                </div>
            </form>
        );
    }
}
export default FormOutbound