import React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.svg';
import Logo2023 from '../images/clu_logoconsulcesi2023.png';
import './NavMenu.css';

const LayoutAnonymous = (props) => {
    return (
        <div>
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <img src={Logo2023} alt="Protocollo" title="Protocollo" style={{ width: "210px" }} />
                        </NavbarBrand>
                    </Container>
                </Navbar>
            </header>
            <Container>
                {props.children}
            </Container>
        </div>
    );
}
export default LayoutAnonymous