import { AuthenticationContext, adalFetch } from 'react-adal';
import { trackEvent, trackException } from './AppInsights';
export const adalConfig = {
    tenant: process.env.REACT_APP_AD_TENANT,
    clientId: process.env.REACT_APP_AD_CLIENTID,
    redirectUri: process.env.REACT_APP_AD_REDIRECTURI,
    endpoints: {
        api: process.env.REACT_APP_AD_CLIENTID
    },
    cacheLocation: 'localStorage' //sessionStorage
};
export const authContext = new AuthenticationContext(adalConfig);
export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
export const adalApiFetch = (fetch, url, options) => adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);
export const requestOptions = {
    crossDomain: true,
    mode: "cors",
    redirect: "follow",
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache'
    }
};
export const verifyUsersRoles = async (decodedToken) => {
    //Verify if the token contains user's infos and user's roles
    if (decodedToken !== null && decodedToken !== undefined && decodedToken !== "" &&
        decodedToken.roles !== null && decodedToken.roles !== undefined && decodedToken.roles !== "") {
        var sereteriaRoles = process.env.REACT_APP_SEGRETERIA_ROLES.split(",");
        var callCenterRoles = process.env.REACT_APP_CALLCENTER_ROLES.split(",");
        //Verify if the user's roles are allowed and remove not allowed roles from the array
        await decodedToken.roles.forEach((roleName, index) => {
            if (!sereteriaRoles.includes(roleName) && !callCenterRoles.includes(roleName)) {
                decodedToken.roles.splice(index, 1);
            }
        })
        //Let the user choose a profile
        if (decodedToken.roles.length <= 0) {
            //User has no allowed roles
            trackException(`User ${decodedToken.upn} has roles inside the token but no one is allowed.`, { name: decodedToken.name, upn: decodedToken.upn, roles: JSON.stringify(decodedToken.roles) });
            return {
                status: 'error', message: `User ${decodedToken.upn} has roles inside the token but no one is allowed.`, allowedUserRoles: [] };
        }
        trackEvent(`User ${decodedToken.upn} has allowed roles inside the token, user logged in.`, { name: decodedToken.name, upn: decodedToken.upn, roles: JSON.stringify(decodedToken.roles) });
        return {
            status: 'success', message: `User ${decodedToken.upn} has allowed roles inside the token, user logged in.`, allowedUserRoles: decodedToken.roles };
    }
    else {
        trackException(`User ${decodedToken.upn} hasn\'t any roles inside the token.`, { name: decodedToken.name, upn: decodedToken.upn });
        //User has no roles inside the token
        return { status: 'error', message: 'User ${decodedToken.upn} hasn\'t any roles inside the token.', allowedUserRoles: [] };
    }
    trackException(`Unknown error verifying user roles from token.`);
    return {
        status: 'error', message:`Unknown error verifying user roles from token.`, allowedUserRoles:[]};
}
export const convertRoleToContext = async (roleName) => {
    var theRoleLastTwoChars = roleName.slice(-2);
    switch (theRoleLastTwoChars) {
        case 'CH':
            return 'SA';
        case 'IT':
            return 'IT';
    }
}
export const roleIsReadOnly = (roleName) => {
    var theRoleLastTwoChars = roleName.substring(0, 10);
    switch (theRoleLastTwoChars) {
        case 'Segreteria':
            return false;
        case 'CallCenter':
            return true;
    }
}