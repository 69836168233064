import React, { useState, useContext } from 'react';
import ProtocolloContext from '../ProtocolloContext';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import Logo from '../images/logo.svg';
import Logo2023 from '../images/clu_logoconsulcesi2023.png';
import './NavMenu.css';


const NavMenu = (props) => {

    const [protocolloState, setProtocolloState] = useContext(ProtocolloContext);
    const [collapsed, setCollapsed] = useState(false);
    const [currentSection, setCurrentSection] = useState(window.location.pathname);
    const toggleNavbar = (newState) => {
        setCollapsed(newState);
    }
    const saveCurrentSection = (ItemName) => {
        setCurrentSection(ItemName);
    }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container>
                    <NavbarBrand tag={Link} to="/">
                        <img src={Logo2023} alt="Protocollo" title="Protocollo" style={{ width: "210px" }} />
                        {(protocolloState.context !== "" && protocolloState.context !== undefined && protocolloState.context !== null) ?
                            <span className={(protocolloState.context === "SA") ? "protocollo-ch" : "protocollo-it"}>
                                {(protocolloState.context === "SA") ? "CH" : "IT"}
                            </span>
                            :
                            null
                        }
                    </NavbarBrand>
                    <NavbarToggler onClick={() => toggleNavbar(!collapsed)} className="mr-2" />
                    <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={collapsed} navbar>
                        <ul className="navbar-nav flex-grow">
                            {(!protocolloState.roleIsReadOnly) ?
                                <NavItem>
                                    <NavLink
                                        tag={Link}
                                        onClick={() => saveCurrentSection("/")}
                                        className={"text-dark" + ((currentSection === "/") ? " currentSection" : "")}
                                        to="/">Registrazione</NavLink>
                                </NavItem>
                                : null
                            }
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    onClick={() => saveCurrentSection("/inbound")}
                                    className={"text-dark" + ((currentSection === "/inbound") ? " currentSection" : "")}
                                    to="/inbound">Ingresso</NavLink>
                            </NavItem>
                            {(!protocolloState.roleIsReadOnly) ?
                                <NavItem>
                                    <NavLink
                                        tag={Link}
                                        onClick={() => saveCurrentSection("/outbound")}
                                        className={"text-dark" + ((currentSection === "/outbound") ? " currentSection" : "")}
                                        to="/outbound">Uscita</NavLink>
                                </NavItem>
                                : null
                            }
                        </ul>
                    </Collapse>
                </Container>
            </Navbar>
            <div className="container text-right text-sm"><small>{window.sessionStorage.getItem("UserFullName")}</small></div>
        </header>
    );
}
export default NavMenu;