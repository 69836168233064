import React, { useState, useEffect, useContext } from 'react';
import ProtocolloContext from '../ProtocolloContext';
import { adalApiFetch, requestOptions } from '../adalConfig';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import { DayPickerStrings } from './DayPickerStrings';
import Loading from './Loading';
import moment from 'moment';
import 'moment-timezone';
import './Inbound.css';
import {
    Stack,
    Dropdown,
    DefaultButton,
    TextField,
    PrimaryButton,
    DatePicker,
    Fabric,
    SearchBox,
    DetailsList,
    Modal,
    IconButton,
    getTheme,
    mergeStyleSets,
    FontWeights,
    Icon,
    ComboBox,
} from '@fluentui/react';

const Inbound = (props) => {

    initializeIcons(undefined, { disableWarnings: true });
    const [protocolloState, setProtocolloState] = useContext(ProtocolloContext);
    const [isLoadingOptionLists, setIsLoadingOptionLists] = useState(false);
    const [isDoingUpdate, setIsDoingUpdate] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inboundItems, setInboundItems] = useState({});
    //const [totalPages, setTotalPages] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [resultsNumber, setResultsNumber] = useState(0);
    //const [resultsPerPage, setResultsPerPage] = useState(0);
    const [errorUpdatingMessage, setErrorUpdatingMessage] = useState("");
    const [resultsShown, setResultsShown] = useState(0);
    const [showResults, setShowResults] = useState(false);
    const [optionsDocumento, setOptionsDocumento] = useState([]);
    const [optionsMezzo, setOptionsMezzo] = useState([]);
    const [optionsArea, setOptionsArea] = useState([]);
    const [optionsRicezione, setOptionsRicezione] = useState([]);
    const [selectedMezzo, setSelectedMezzo] = useState([]);
    const [selectedArea, setSelectedArea] = useState([]);
    const [selectedDocumento, setSelectedDocumento] = useState([]);
    const [selectedRicezione, setSelectedRicezione] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRowDetails, SetSelectedRowDetails] = useState([]);
    const [updateData, setUpdateData] = useState({ idProtocollo: '', fieldName: '', fieldValue: '', filedLabel: '' });
    const [showEditArea, setShowEditArea] = useState(false);
    const [showEditRicezione, setShowEditRicezione] = useState(false);
    const [showEditMezzo, setShowEditMezzo] = useState(false);
    const [showEditIdTicket, setShowEditIdTicket] = useState(false);
    const [showEditNote, setShowEditNote] = useState(false);
    const [showEditDataArrivo, setShowEditDataArrivo] = useState(false);
    const [showEditDataInvio, setShowEditDataInvio] = useState(false);
    const [showEditDocumenti, setShowEditDocumenti] = useState(false);
    const [showEditMittente, setShowEditMittente] = useState(false);
    const [editDataArrivoIntialValue, setEditDataArrivoIntialValue] = useState(null);
    const [editDataInvioIntialValue, setEditDataInvioIntialValue] = useState(null);
    const [editDocumentiIntialValue, setEditDocumentiIntialValue] = useState([]);
    const [editDocumentiSelected, setEditDocumentiSelected] = useState([]);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [isDoingDelete, setIsDoingDelete] = useState(false);
    const theme = getTheme();
    const stackTokens = { childrenGap: 10 };
    const iconDelete = { iconName: "Cancel" };
    const contentStyles = mergeStyleSets({
        container: {
            display: "flex",
            flexFlow: "column nowrap",
            alignItems: "stretch",
        },
        header: [
            theme.fonts.xLargePlus,
            {
                flex: "1 1 auto",
                borderTop: `4px solid ${theme.palette.themePrimary}`,
                color: theme.palette.neutralPrimary,
                display: "flex",
                alignItems: "center",
                fontWeight: FontWeights.semibold,
                padding: "12px 12px 14px 24px",
            },
        ],
        body: {
            flex: "4 4 auto",
            padding: "0 24px 24px 24px",
            overflowY: "hidden",
            selectors: {
                p: { margin: "14px 0" },
                "p:first-child": { marginTop: 0 },
                "p:last-child": { marginBottom: 0 },
            },
        },
    });
    const iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: "auto",
            marginTop: "4px",
            marginRight: "2px",
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };
    const generateGetInboundUrl = (page) => {
        var urlToCall = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/protocolloinbound?context=${protocolloState.context}&nominativo={nominativo}&periodoDal={periodoDal}&periodoAl={periodoAl}&area={area}&documento={documento}&mezzo={mezzo}&ricezione={ricezione}&page={page}`;
        page = page != null ? page : currentPage;
        //PERIODO DAL
        var periodoDal = window.sessionStorage.getItem("IN_PeriodoDal");
        urlToCall =
            periodoDal !== "" && periodoDal !== null && periodoDal.length > 0
                ? urlToCall.replace("{periodoDal}", moment(periodoDal).format("YYYY-MM-DD"))
                : urlToCall.replace("{periodoDal}", "");
        //PERIODO AL
        var periodoAl = window.sessionStorage.getItem("IN_PeriodoAl");
        urlToCall =
            periodoAl !== "" && periodoAl !== null && periodoAl.length > 0
                ? urlToCall.replace("{periodoAl}", moment(periodoAl).format("YYYY-MM-DD"))
                : urlToCall.replace("{periodoAl}", "");
        //NOMINATIVO
        var nominativo = window.sessionStorage.getItem("IN_Nominativo");
        urlToCall =
            nominativo !== "" && nominativo !== null && nominativo.length > 0
                ? urlToCall.replace("{nominativo}", nominativo)
                : urlToCall.replace("{nominativo}", "");
        //AREA
        var area = window.sessionStorage.getItem("IN_Area");
        if (area !== "" && area !== null && area.length > 0) {
            var AreaArray = JSON.parse(area);
            let stringToAppend = "";
            AreaArray.forEach((item, index) => {
                stringToAppend += item.key + ",";
            });
            urlToCall = urlToCall.replace(
                "{area}",
                stringToAppend.replace(/,\s*$/, "")
            );
        } else {
            urlToCall = urlToCall.replace("{area}", "");
        }
        //MEZZO
        var mezzo = window.sessionStorage.getItem("IN_Mezzo");
        if (mezzo !== "" && mezzo !== null && mezzo.length > 0) {
            var MezzoArray = JSON.parse(mezzo);
            let stringToAppend = "";
            MezzoArray.forEach((item, index) => {
                stringToAppend += item.key + ",";
            });
            urlToCall = urlToCall.replace(
                "{mezzo}",
                stringToAppend.replace(/,\s*$/, "")
            );
        } else {
            urlToCall = urlToCall.replace("{mezzo}", "");
        }
        //RICEZIONE
        var ricezione = window.sessionStorage.getItem("IN_Ricezione");
        if (ricezione !== "" && ricezione !== null && ricezione.length > 0) {
            var RicezioneArray = JSON.parse(ricezione);
            let stringToAppend = "";
            RicezioneArray.forEach((item, index) => {
                stringToAppend += item.key + ",";
            });
            urlToCall = urlToCall.replace(
                "{ricezione}",
                stringToAppend.replace(/,\s*$/, "")
            );
        } else {
            urlToCall = urlToCall.replace("{ricezione}", "");
        }
        //DOCUMENTO
        var documento = window.sessionStorage.getItem("IN_Documento");
        if (documento !== "" && documento !== null && documento.length > 0) {
            var DocumentoArray = JSON.parse(documento);
            let stringToAppend = "";
            DocumentoArray.forEach((item, index) => {
                stringToAppend += item.key + ",";
            });
            urlToCall = urlToCall.replace(
                "{documento}",
                stringToAppend.replace(/,\s*$/, "")
            );
        } else {
            urlToCall = urlToCall.replace("{documento}", "");
        }
        //PAGE
        urlToCall =
            page !== "" && page !== null
                ? urlToCall.replace("{page}", page)
                : urlToCall.replace("{page}", 1);

        if (protocolloState.roleIsReadOnly) {
            urlToCall = urlToCall + "&limitToLegalAndServices=true";
        }
        else {
            urlToCall = urlToCall + "&limitToLegalAndServices=false";
        }

        return urlToCall;
    };
    const inboundColumns = [
        {
            key: "dataArrivo",
            name: "Data Arr.",
            fieldName: "dataArrivo",
            minWidth: 70,
            maxWidth: 70,
            isResizable: false,
        },
        {
            key: "mittente",
            name: "Mittente",
            fieldName: "mittente",
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
        },
        {
            key: "area",
            name: "Area",
            fieldName: "area",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "mezzo",
            name: "Mezzo",
            fieldName: "mezzo",
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
        },
        /*{
            key: "ricezione",
            name: "Ricezione",
            fieldName: "ricezione",
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
        },*/
        {
            key: "documenti",
            name: "Documenti",
            fieldName: "documenti",
            minWidth: 200,
            maxWidth: 300,
            isResizable: true,
            onRender: (item) => {
                var DocList = "";
                if (
                    item.documenti &&
                    item.documenti != null &&
                    item.documenti.length > 0
                ) {
                    item.documenti.forEach((doc) => {
                        DocList += doc.descrizione + ", ";
                    });
                }
                return DocList.replace(/,\s*$/, "");
            },
        },
        {
            key: "dataInserimento",
            name: "Inserito il",
            fieldName: "dataInserimento",
            minWidth: 70,
            maxWidth: 70,
            isResizable: false,
        },
    ];
    const selectItems = (itemName, valueToAdd) => {
        var ItemsSaved = window.sessionStorage.getItem("IN_" + itemName);
        if (ItemsSaved == null) {
            ItemsSaved = JSON.stringify([valueToAdd]);
            window.sessionStorage.setItem("IN_" + itemName, ItemsSaved);
        } else {
            var ItemsArray = JSON.parse(ItemsSaved);
            if (valueToAdd.selected) {
                //ADD
                ItemsArray.pushIfNotExist(valueToAdd, function (e) {
                    return e.key === valueToAdd.key && e.text === valueToAdd.text;
                });
            } else {
                //REMOVE
                ItemsArray.forEach((item, index) => {
                    if (item.key === valueToAdd.key) {
                        ItemsArray.splice(index, 1);
                    }
                });
            }
            if (ItemsArray.length > 0) {
                ItemsSaved = JSON.stringify(ItemsArray);
                window.sessionStorage.setItem("IN_" + itemName, ItemsSaved);
            } else {
                window.sessionStorage.removeItem("IN_" + itemName);
            }
            //console.log(ItemsArray);
        }
    };
    const setFieldCookie = (itemName, valueToUpdate) => {
        if (valueToUpdate !== "")
            window.sessionStorage.setItem("IN_" + itemName, valueToUpdate);
        else window.sessionStorage.removeItem("IN_" + itemName);
    };
    const clearSearchForm = () => {
        window.sessionStorage.removeItem("IN_Area");
        window.sessionStorage.removeItem("IN_Mezzo");
        window.sessionStorage.removeItem("IN_Ricezione");
        window.sessionStorage.removeItem("IN_Documento");
        window.sessionStorage.removeItem("IN_Nominativo");
        window.sessionStorage.removeItem("IN_PeriodoDal");
        window.sessionStorage.removeItem("IN_PeriodoAl");
        document.location.reload();
    };
    const LoadInboundList = async (page, clearList = true) => {
        if (!isLoading) {
            setIsLoading(true);
            var btn = document.getElementById("btnSearch");
            btn.disabled = true;
            btn.innerHTML = "Loading";
            if (clearList) {
                setInboundItems([]);
                setResultsShown("--");
                setResultsNumber("--");
            }
            var url = generateGetInboundUrl(page);
            var optionsGet = requestOptions;
            optionsGet.method = 'GET';
            optionsGet.body = null;
            await adalApiFetch(fetch, url, optionsGet)
                .then((response) => response.json())
                .then((data) => {
                    if (data != null) {
                        if (data.totalResults !== null) {
                            //console.log(data);
                            setResultsNumber(data.totalResults);
                            if (page <= 1) {
                                setInboundItems(data.results);
                            } else {
                                var tempInboundItems = [...inboundItems];
                                var newList = tempInboundItems.concat(data.results);
                                setInboundItems(newList);
                            }
                            if (data.pages <= 1 || page <= 1) {
                                setResultsShown(data.results.length);
                            } else {
                                setResultsShown(resultsShown + data.results.length);
                            }
                            setCurrentPage(data.page);
                            //setTotalPages(data.pages);
                            //setResultsPerPage(data.resultsPerPage);
                            setShowResults(true);
                            btn.disabled = false;
                            btn.innerHTML = "Cerca";
                            setIsLoading(false);
                        } else {
                            btn.disabled = false;
                            btn.innerHTML = "Cerca";
                            setIsLoading(false);
                            return setInboundItems({});
                        }
                    } else {
                        btn.disabled = false;
                        btn.innerHTML = "Cerca";
                        setIsLoading(false);
                        return setInboundItems({});
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const loadMore = () => {
        var page = currentPage + 1;
        setCurrentPage(page);
        LoadInboundList(page, false);
    };
    const LoadOptionsList = async (optionListName) => {

        var cachedOptionList = sessionStorage.getItem("INBOUND_LIST_" + optionListName);
        if (cachedOptionList) {

            if (optionListName === "Area") {
                setOptionsArea(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Documento") {
                setOptionsDocumento(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Ricezione") {
                setOptionsRicezione(JSON.parse(cachedOptionList));
            }
            if (optionListName === "Mezzo") {
                setOptionsMezzo(JSON.parse(cachedOptionList));
            }

        } else {
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/optionsList?context=${protocolloState.context}&optionListName=${optionListName}`;

            if (optionListName === "Area" && protocolloState.roleIsReadOnly) {
                url = url + "&limitToLegalAndServices=true";
            }
            else {
                url = url + "&limitToLegalAndServices=false";
            }

            var optionsGet = requestOptions;
            optionsGet.method = 'get';
            optionsGet.body = null;
            await adalApiFetch(fetch, url, optionsGet)
                .then((response) => response.json())
                .then((data) => {
                    if (optionListName === "Area") {
                        let newData = [...data];
                        newData.unshift({ text: "Nessuna", key: "", });
                        setOptionsArea(newData);
                        sessionStorage.setItem("INBOUND_LIST_" + optionListName, JSON.stringify(newData));
                    }
                    if (optionListName === "Documento") {
                        setOptionsDocumento(data);
                        sessionStorage.setItem("INBOUND_LIST_" + optionListName, JSON.stringify(data));
                    }
                    if (optionListName === "Ricezione") {
                        let newData = [...data];
                        newData.unshift({ text: "Non specificato", key: "", });
                        setOptionsRicezione(newData);
                        sessionStorage.setItem("INBOUND_LIST_" + optionListName, JSON.stringify(newData));
                    }
                    if (optionListName === "Mezzo") {
                        let newData = [...data];
                        newData.unshift({ text: "Non specificato", key: "", });
                        setOptionsMezzo(newData);
                        sessionStorage.setItem("INBOUND_LIST_" + optionListName, JSON.stringify(newData));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        }

    };
    const loadCookies = async (optionListName) => {
        var ArraySelected = [];
        var ItemsSaved = window.sessionStorage.getItem("IN_" + optionListName);
        if (ItemsSaved != null) {
            var ItemsArray = JSON.parse(ItemsSaved);
            if (ItemsArray.length > 0) {
                ItemsArray.forEach((item, index) => {
                    ArraySelected.push(item.key);
                });
            }
        }
        debugger;
        if (optionListName === "Area") {
            setSelectedArea(ArraySelected);
        }
        if (optionListName === "Documento") {
            setSelectedDocumento(ArraySelected);
        }
        if (optionListName === "Ricezione") {
            setSelectedRicezione(ArraySelected);
        }
        if (optionListName === "Mezzo") {
            setSelectedMezzo(ArraySelected);
        }
    };
    const rowClick = async (item, index) => {
        var selectedDocuments = [];
        var docSelected = [];
        item.documenti.forEach((item) => {
            selectedDocuments.push(item.idDocumento)
            docSelected.push({ text: item.descrizione, key: item.idDocumento, selected: true })
        })
        setEditDocumentiSelected(docSelected);
        setEditDocumentiIntialValue(selectedDocuments)
        setIsModalOpen(true);
        SetSelectedRowDetails(item);
    };
    const resetAllEditFields = () => {
        setErrorUpdatingMessage("");
        setShowEditArea(false);
        setShowEditMezzo(false);
        setShowEditRicezione(false);
        setShowEditIdTicket(false);
        setShowEditNote(false);
        setShowEditDataArrivo(false);
        setShowEditDataInvio(false);
        setShowEditDocumenti(false);
        setShowEditMittente(false);
    }
    const handleShowEditInboundField = (fieldName, show) => {
        setErrorUpdatingMessage("");
        setUpdateData({ idProtocollo: '', fieldName: '', fieldValue: '', fieldLabel: '' });
        switch (fieldName) {
            case 'Area':
                setShowEditArea(show);
                break;
            case 'Mezzo':
                setShowEditMezzo(show);
                break;
            case 'Ricezione':
                setShowEditRicezione(show);
                break;
            case 'IdTicket':
                setShowEditIdTicket(show);
                break;
            case 'Mittente':
                setShowEditMittente(show);
                break;
            case 'Note':
                setShowEditNote(show);
                break;
            case 'DataArrivo':
                setShowEditDataArrivo(show);
                break;
            case 'DataInvio':
                setShowEditDataInvio(show);
                break;
            case 'Documenti':
                setEditDocumentiIntialValue([]);
                setShowEditDocumenti(show);
                break;
        }
    }
    const fetchUpdateInbound = async (selectedRowDetails) => {
        setErrorUpdatingMessage("");
        setIsDoingUpdate(true);
        var blockSend = false;
        if (updateData.idProtocollo === "" ||
            updateData.idProtocollo === undefined ||
            updateData.fieldLabel === undefined ||
            updateData.fieldName === "" ||
            updateData.fieldName === undefined ||
            (updateData.fieldName !== "Documenti" && updateData.fieldValue === "") ||
            updateData.fieldValue === undefined
        ) {
            blockSend = true;
        }
        if (blockSend) {
            setIsDoingUpdate(false);
            return false;
        }
        else {
            document.getElementById("btnUpdateInbound" + updateData.fieldName).disabled = true;
            setIsDoingUpdate(true);
            updateData.context = protocolloState.context;
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/protocolloinbound`;
            var optionsPost = requestOptions;
            optionsPost.method = 'put';
            optionsPost.body = JSON.stringify(updateData);
            await adalApiFetch(fetch, url, optionsPost)
                .then((response) => response.json())
                .then((data) => {
                    if (data.updateStatus === true) {

                        if (updateData.fieldName == "Documenti") {
                            var itemdocs = "";
                            if (updateData.fieldLabel !== "") {
                                updateData.fieldLabel.split(",").forEach((item) => {
                                    itemdocs += `<span class="documentClass">${item}</span>`;
                                })
                            }
                            var temp = [...editDocumentiSelected];
                            var tempInbound = [...inboundItems];
                            var itemdocs = [];
                            if (temp.length > 0) {
                                temp.forEach((t) => {
                                    itemdocs.push({ idDocumento: t.key, descrizione: t.text });
                                })
                            }
                            var objIndex = tempInbound.findIndex((obj => obj.idProtocollo === updateData.idProtocollo));
                            tempInbound[objIndex].documenti = itemdocs;
                        }
                        else {
                            document.getElementById("ValueLabel" + updateData.fieldName).innerHTML = updateData.fieldLabel;
                        }

                        SetSelectedRowDetails(selectedRowDetails);
                        handleShowEditInboundField(updateData.fieldName, false)
                    }
                    else if (data.updateStatus === false) {
                        setErrorUpdatingMessage(`Si sono verificati errori durante l'aggiornamento del campo ${updateData.fieldName}`);
                    }
                    setIsDoingUpdate(false);
                    document.getElementById("btnUpdateInbound" + updateData.fieldName).disabled = false;
                })
                .catch((error) => {
                    console.log(error);
                    setIsDoingUpdate(false);
                });
        }
    }
    const deleteProtocollo = async (idProtocollo) => {
        setIsDoingDelete(true);
        if (isNaN(idProtocollo)) {
            setErrorUpdatingMessage(`Si sono verificati errori durante l'eliminazione, id protocollo non valido.`);
            setIsDoingDelete(false);
            return false;
        }
        else {
            //console.log("Elimino protocollo ", idProtocollo);
            var url = `${process.env.REACT_APP_PROTOCOLLO_API_ENDPOINT}/protocolloinbound`;
            var optionsPost = requestOptions;
            optionsPost.method = 'delete';
            optionsPost.body = JSON.stringify({ idProtocollo: idProtocollo, Context: protocolloState.context });
            await adalApiFetch(fetch, url, optionsPost)
                .then((response) => response.json())
                .then((data) => {
                    if (data.deleteStatus === true) {
                        var tempInbound = [...inboundItems];
                        var newInboundList = tempInbound.filter((obj => obj.idProtocollo !== idProtocollo));
                        setInboundItems(newInboundList);
                        setErrorUpdatingMessage(``);
                        setIsModalOpen(false);
                        setShowConfirmDelete(false);
                        return true;
                    }
                    else if (data.deleteStatus === false) {
                        setErrorUpdatingMessage(`Si sono verificati errori durante l'eliminazione.`);
                        return false;
                    }
                    
                    if (data.error && data.error !== undefined && data.error !== "") {
                        setErrorUpdatingMessage(`Si sono verificati errori durante l'eliminazione. ${data.error}`);
                        return false;
                    }

                    setIsDoingDelete(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsDoingDelete(false);
                });
        }
    }
    useEffect(() => {
        async function fetchOptionsList() {
            window.sessionStorage.setItem("IN_PeriodoDal", new Date(new Date().getFullYear(), new Date().getMonth(), 1))
            window.sessionStorage.setItem("IN_PeriodoAl", new Date())
            setIsLoadingOptionLists(true);
            await loadCookies("Mezzo");
            await LoadOptionsList("Mezzo");
            await loadCookies("Documento");
            await LoadOptionsList("Documento");
            await loadCookies("Area");
            await LoadOptionsList("Area");
            await loadCookies("Ricezione");
            await LoadOptionsList("Ricezione");
            setIsLoadingOptionLists(false);
        }
        fetchOptionsList();
    }, []);
    return (isLoadingOptionLists) ?
        <Loading message="Attendere..." />
        :
        <div>
            <h1><div className="ms-font-su ms-fontColor-themePrimary">Ingresso</div></h1>
            <Fabric>
                <Stack tokens={stackTokens}>
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                <DatePicker id="PeriodoDal"
                                    strings={DayPickerStrings}
                                    disableAutoFocus={true}
                                    value={
                                        window.sessionStorage.getItem("IN_PeriodoDal") != null
                                            ? new Date(window.sessionStorage.getItem("IN_PeriodoDal"))
                                            : new Date(new Date().getFullYear(), new Date().getMonth(), 1)
                                    }
                                    maxDate={
                                        window.sessionStorage.getItem("IN_PeriodoAl") != null
                                            ? new Date(window.sessionStorage.getItem("IN_PeriodoAl"))
                                            : null
                                    }
                                    firstWeekOfYear={1}
                                    firstDayOfWeek={1}
                                    showMonthPickerAsOverlay={true}
                                    placeholder="Select a date..."
                                    ariaLabel="Periodo Dal"
                                    label="Periodo Dal"
                                    showGoToToday={false}
                                    showWeekNumbers={false}
                                    formatDate={(date) => {
                                        return (
                                            date.getDate() +
                                            "/" +
                                            (date.getMonth() + 1) +
                                            "/" +
                                            date.getFullYear()
                                        );
                                    }}
                                    onSelectDate={(date) =>
                                        window.sessionStorage.setItem("IN_PeriodoDal", date)
                                    }
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                <DatePicker id="PeriodoAl"
                                    strings={DayPickerStrings}
                                    value={
                                        window.sessionStorage.getItem("IN_PeriodoAl") != null
                                            ? new Date(window.sessionStorage.getItem("IN_PeriodoAl"))
                                            : new Date()
                                    }
                                    minDate={
                                        window.sessionStorage.getItem("IN_PeriodoDal") != null
                                            ? new Date(window.sessionStorage.getItem("IN_PeriodoDal"))
                                            : null
                                    }
                                    firstWeekOfYear={1}
                                    firstDayOfWeek={1}
                                    showMonthPickerAsOverlay={true}
                                    disableAutoFocus={true}
                                    placeholder="Select a date..."
                                    ariaLabel="Periodo Al"
                                    label="Periodo Al"
                                    showGoToToday={false}
                                    showWeekNumbers={false}
                                    formatDate={(date) => {
                                        return (
                                            date.getDate() +
                                            "/" +
                                            (date.getMonth() + 1) +
                                            "/" +
                                            date.getFullYear()
                                        );
                                    }}
                                    onSelectDate={(date) =>
                                        window.sessionStorage.setItem("IN_PeriodoAl", date)
                                    }
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6">
                                <div className="ms-TextField-wrapper">
                                    <label className="protocolloLabels">Nominativo</label>
                                    <SearchBox id="Nominativo"
                                        placeholder="Inserisci nome o cognome"
                                        value={window.sessionStorage.getItem("IN_Nominativo")}
                                        onChange={(item, value) =>
                                            setFieldCookie("Nominativo", value)
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ms-Grid-row ">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                {/*<Dropdown id="Area"*/}
                                {/*    placeholder="Scegli Area"*/}
                                {/*    label="Area"*/}
                                {/*    multiSelect*/}
                                {/*    options={(protocolloState.roleIsReadOnly) ? optionsArea.filter(a => a.key == 1 || a.key == 2) : optionsArea}*/}
                                {/*    defaultSelectedKeys={selectedArea}*/}
                                {/*    onChange={(item, selectedOption) => {*/}
                                {/*        debugger;*/}
                                {/*        selectItems("Area", selectedOption)*/}
                                {/*    }}*/}
                                {/*/>*/}

                                <ComboBox id="Area"
                                    placeholder="Scegli Area"
                                    label="Area"
                                    multiSelect
                                    allowFreeform={true}
                                    autoComplete="on"
                                    options={(protocolloState.roleIsReadOnly) ? optionsArea.filter(a => a.key == 1 || a.key == 2) : optionsArea}
                                    defaultSelectedKeys={selectedArea}
                                    onChange={(item, selectedOption) => {
                                        selectItems("Area", selectedOption)
                                    }}
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                {/*<Dropdown placeholder="Scegli Mezzo"*/}
                                {/*    label="Mezzo"*/}
                                {/*    multiSelect*/}
                                {/*    options={optionsMezzo}*/}
                                {/*    defaultSelectedKeys={selectedMezzo}*/}
                                {/*    onChange={(item, selectedOption) =>*/}
                                {/*        selectItems("Mezzo", selectedOption)*/}
                                {/*    }*/}
                                {/*/>*/}
                                <ComboBox
                                    placeholder="Scegli Mezzo"
                                    label="Mezzo"
                                    multiSelect
                                    allowFreeform={true}
                                    autoComplete="on"
                                    options={optionsMezzo}
                                    defaultSelectedKeys={selectedMezzo}
                                    onChange={(item, selectedOption) =>
                                        selectItems("Mezzo", selectedOption)
                                    }
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                                {/*<Dropdown placeholder="Scegli tipo documento"*/}
                                {/*    label="Tipo Documento"*/}
                                {/*    multiSelect*/}
                                {/*    options={optionsDocumento}*/}
                                {/*    defaultSelectedKeys={selectedDocumento}*/}
                                {/*    onChange={(item, selectedOption) =>*/}
                                {/*        selectItems("Documento", selectedOption)*/}
                                {/*    }*/}
                                {/*/>*/}
                                <ComboBox
                                    placeholder="Scegli tipo documento"
                                    label="Tipo Documento"
                                    multiSelect
                                    allowFreeform={true}
                                    autoComplete="on"
                                    options={optionsDocumento}
                                    defaultSelectedKeys={selectedDocumento}
                                    onChange={(item, selectedOption) =>
                                        selectItems("Documento", selectedOption)
                                    }
                                />
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg3">
                            {/*    <Dropdown placeholder="Scegli Ricezione"*/}
                            {/*        label="Ricezione"*/}
                            {/*        multiSelect*/}
                            {/*        options={optionsRicezione}*/}
                            {/*        defaultSelectedKeys={selectedRicezione}*/}
                            {/*        onChange={(item, selectedOption) =>*/}
                            {/*            selectItems("Ricezione", selectedOption)*/}
                            {/*        }*/}
                            {/*    />*/}
                                <ComboBox
                                    placeholder="Scegli Ricezione"
                                    label="Ricezione"
                                    multiSelect
                                    allowFreeform={true}
                                    autoComplete="on"
                                    options={optionsRicezione}
                                    defaultSelectedKeys={selectedRicezione}
                                    onChange={(item, selectedOption) =>
                                        selectItems("Ricezione", selectedOption)
                                    }
                                />

                            </div>
                        </div>
                        <div className="ms-Grid-row"
                            style={{
                                display: "flex",
                                alignItems: "center",
                                "marginTop": "10px",
                            }}>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                {showResults ? (
                                    <div>
                                        {resultsShown} Risultati mostrati su {resultsNumber}{" "}
                                        Trovati
                            </div>
                                ) : null}
                            </div>
                            <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6"
                                style={{ display: "flex", justifyContent: "flex-end" }}>
                                <DefaultButton text="Pulisci"
                                    style={{ "marginRight": "5px" }}
                                    onClick={() =>
                                        clearSearchForm()}
                                />
                                <PrimaryButton id="btnSearch"
                                    text="Cerca"
                                    style={{ minWidth: "100px" }}
                                    onClick={() =>
                                        LoadInboundList(1)}
                                />
                            </div>
                        </div>
                    </div>
                    <div id="risultati-container" className="ms-Grid-row">
                        {showResults ? (
                            <DetailsList compact={true}
                                items={inboundItems}
                                checkboxVisibility={2}
                                columns={inboundColumns}
                                setKey="set"
                                selectionPreservedOnEmptyClick={true}
                                onActiveItemChanged={(item) =>
                                    rowClick(item)}
                                onItemInvoked={(item) => rowClick(item)}
                                ariaLabelForSelectionColumn="Toggle selection"
                                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                                checkButtonAriaLabel="Row checkbox"
                            />
                        ) : null}
                    </div>
                    {showResults && resultsShown < resultsNumber ? (
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div id="loadMore" className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    {!isLoading ? (
                                        <DefaultButton
                                            id="btnLoadMore"
                                            onClick={() => loadMore()}
                                            text="Carica altro"
                                            allowDisabledFocus />
                                    ) : (
                                            <div>Loading...</div>
                                        )}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <Modal titleAriaId="ModalDetails"
                        isOpen={isModalOpen}
                        onDismiss={() => {
                            setIsModalOpen(false);
                        }}
                        isBlocking={false}
                        containerClassName={contentStyles.container}
                    >
                        <div className={contentStyles.header}>
                            <span id="ModalDetailsTitle" style={{ minWidth: (protocolloState.roleIsReadOnly) ? "500px" : "700px" }}>
                                #{selectedRowDetails.idProtocollo}
                            </span>
                            <IconButton styles={iconButtonStyles}
                                iconProps={iconDelete}
                                ariaLabel="Close popup modal"
                                onClick={() => {
                                    setIsModalOpen(false); resetAllEditFields();
                                }}
                            />
                        </div>
                        <div className={contentStyles.body} id="ModalDetailsContent">

                            <div className="DetailsRow d-flex">
                                <div className={(showEditMittente) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                    <div>
                                        <Icon iconName="FollowUser" /> <b>Mittente:</b>{" "}
                                        <span id="ValueLabelMittente">{selectedRowDetails.mittente}</span>
                                    </div>
                                    {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditMittente) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditInboundField("Mittente", true)}>[modifica]</a>}
                                </div>
                                <div className={(showEditMittente) ? "col-6 d-inline" : "col-6 d-none"}>
                                    <div className={(showEditMittente) ? "d-inline" : "d-none"}>
                                        <TextField
                                            id="Mittente"
                                            defaultValue={selectedRowDetails.mittente}
                                            onChange={(event, value) => {
                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Mittente", fieldValue: value, fieldLabel: value })
                                            }}
                                        />
                                        <DefaultButton text="Annulla"
                                            style={{ "marginRight": "5px", marginTop: "5px" }}
                                            onClick={() => {
                                                handleShowEditInboundField("Mittente", false)
                                            }}
                                        />
                                        <PrimaryButton
                                            id="btnUpdateInboundMittente"
                                            text="Salva"
                                            style={{ minWidth: "100px", marginTop: "5px" }}
                                            onClick={() => {
                                                fetchUpdateInbound(selectedRowDetails).then(() => { selectedRowDetails.mittente = updateData.fieldLabel; })
                                            }}
                                            allowDisabledFocus
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="DetailsRow d-flex">
                                <div className={(showEditArea) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                    <div>
                                        <Icon iconName="Telemarketer" /> <b>Area<sup className="text-danger">*</sup> :</b>{" "}
                                        <span id="ValueLabelArea">{selectedRowDetails.area}</span>
                                    </div>
                                    {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditArea) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditInboundField("Area", true)}>[modifica]</a>}
                                </div>
                                <div className={(showEditArea) ? "col-6 d-inline" : "col-6 d-none"}>
                                    <div className={(showEditArea) ? "d-inline" : "d-none"}>
                                        <Dropdown
                                            id="EditArea"
                                            name="Area"
                                            placeholder="Scegli Area"
                                            options={optionsArea}
                                            onChange={(item, selectedOption) =>
                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Area", fieldValue: selectedOption.key, fieldLabel: selectedOption.text })
                                            }
                                        />
                                        <DefaultButton text="Annulla"
                                            style={{ "marginRight": "5px", marginTop: "5px" }}
                                            onClick={() => {
                                                handleShowEditInboundField("Area", false)
                                            }}
                                        />
                                        <PrimaryButton
                                            id="btnUpdateInboundArea"
                                            text="Salva"
                                            style={{ minWidth: "100px", marginTop: "5px" }}
                                            onClick={() => {
                                                fetchUpdateInbound(selectedRowDetails).then(() => {
                                                    selectedRowDetails.area = updateData.fieldLabel;
                                                    selectedRowDetails.idArea = updateData.fieldValue;
                                                });
                                            }}
                                            allowDisabledFocus
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="DetailsRow d-flex">
                                <div className={(showEditDataArrivo) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                    <div>
                                        <Icon iconName="CalendarReply" /> <b>Data di arrivo<sup className="text-danger">*</sup> :</b>{" "}
                                        <span id="ValueLabelDataArrivo">{selectedRowDetails.dataArrivo}</span>
                                    </div>
                                    {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditDataArrivo) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditInboundField("DataArrivo", true)}>[modifica]</a>}
                                </div>
                                <div className={(showEditDataArrivo) ? "col-6 d-inline" : "col-6 d-none"}>
                                    <div className={(showEditDataArrivo) ? "d-inline" : "d-none"}>
                                        <DatePicker
                                            id="EditDataArrivo"
                                            name="DataArrivo"
                                            strings={DayPickerStrings}
                                            disableAutoFocus={true}
                                            value={editDataArrivoIntialValue}
                                            firstWeekOfYear={1}
                                            firstDayOfWeek={1}
                                            showMonthPickerAsOverlay={true}
                                            placeholder="Scegli data arrivo..."
                                            showGoToToday={false}
                                            showWeekNumbers={false}
                                            maxDate={new Date()}
                                            formatDate={(date) => {
                                                return (
                                                    date.getDate() +
                                                    "/" +
                                                    (date.getMonth() + 1) +
                                                    "/" +
                                                    date.getFullYear()
                                                );
                                            }}
                                            onSelectDate={(date) => {
                                                setEditDataArrivoIntialValue(date);
                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "DataArrivo", fieldValue: date, fieldLabel: moment(date).format("DD/MM/YYYY") })
                                            }}
                                        />
                                        <DefaultButton text="Annulla"
                                            style={{ "marginRight": "5px", marginTop: "5px" }}
                                            onClick={() => {
                                                handleShowEditInboundField("DataArrivo", false)
                                            }}
                                        />
                                        <PrimaryButton
                                            id="btnUpdateInboundDataArrivo"
                                            text="Salva"
                                            style={{ minWidth: "100px", marginTop: "5px" }}
                                            onClick={() => {
                                                fetchUpdateInbound(selectedRowDetails).then(() => { selectedRowDetails.dataArrivo = updateData.fieldLabel; });
                                            }}
                                            allowDisabledFocus
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="DetailsRow d-flex">
                                <div className={(showEditDataInvio) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                    <div>
                                        <Icon iconName="CalendarReply" /> <b>Data di invio:</b>{" "}
                                        <span id="ValueLabelDataInvio">{(selectedRowDetails.dataInvio !== "" && selectedRowDetails.dataInvio !== null && selectedRowDetails.dataInvio !== undefined) ? moment(selectedRowDetails.dataInvio).format("DD/MM/YYYY") : ""}</span>
                                    </div>
                                    {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditDataInvio) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditInboundField("DataInvio", true)}>[modifica]</a>}
                                </div>
                                <div className={(showEditDataInvio) ? "col-6 d-inline" : "col-6 d-none"}>
                                    <div className={(showEditDataInvio) ? "d-inline" : "d-none"}>
                                        <DatePicker
                                            id="EditDataInvio"
                                            name="DataInvio"
                                            strings={DayPickerStrings}
                                            disableAutoFocus={true}
                                            maxDate={
                                                selectedRowDetails.dataArrivo !== null && selectedRowDetails.dataArrivo !== undefined && selectedRowDetails.dataArrivo !== ""
                                                    ? new Date(selectedRowDetails.dataArrivo.split("/")[2], selectedRowDetails.dataArrivo.split("/")[1] - 1, selectedRowDetails.dataArrivo.split("/")[0])
                                                    : null
                                            }
                                            value={editDataInvioIntialValue}
                                            firstWeekOfYear={1}
                                            firstDayOfWeek={1}
                                            showMonthPickerAsOverlay={true}
                                            placeholder="Scegli data invio..."
                                            showGoToToday={false}
                                            showWeekNumbers={false}
                                            formatDate={(date) => {
                                                return (
                                                    date.getDate() +
                                                    "/" +
                                                    (date.getMonth() + 1) +
                                                    "/" +
                                                    date.getFullYear()
                                                );
                                            }}
                                            onSelectDate={(date) => {
                                                setEditDataInvioIntialValue(date);
                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "DataInvio", fieldValue: date, fieldLabel: moment(date).format("DD/MM/YYYY") })
                                            }}
                                        />
                                        <DefaultButton text="Annulla"
                                            style={{ "marginRight": "5px", marginTop: "5px" }}
                                            onClick={() => {
                                                handleShowEditInboundField("DataInvio", false)
                                            }}
                                        />
                                        <PrimaryButton
                                            id="btnUpdateInboundDataInvio"
                                            text="Salva"
                                            style={{ minWidth: "100px", marginTop: "5px" }}
                                            onClick={() => {
                                                fetchUpdateInbound(selectedRowDetails).then(() => { selectedRowDetails.dataInvio = updateData.fieldLabel; });
                                            }}
                                            allowDisabledFocus
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="DetailsRow d-flex">
                                <div className={(showEditDocumenti) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                    <div>
                                        <Icon iconName="Documentation" /> <b>Documenti:</b>{" "}
                                        <span id="ValueLabelDocumenti">
                                            {selectedRowDetails.documenti
                                                ? selectedRowDetails.documenti.map((item, index) => {
                                                    if (item.descrizione !== "") {
                                                        return (
                                                            <span key={index} className="documentClass">
                                                                {item.descrizione}
                                                            </span>
                                                        );
                                                    }
                                                })
                                                : null}
                                        </span>
                                    </div>
                                    {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditDocumenti) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditInboundField("Documenti", true)}>[modifica]</a>}
                                </div>
                                <div className={(showEditDocumenti) ? "col-6 d-inline" : "col-6 d-none"}>
                                    <div className={(showEditDocumenti) ? "d-inline" : "d-none"}>
                                        <Dropdown
                                            id="EditDocumenti"
                                            name="Documenti"
                                            placeholder="Scegli Documenti"
                                            options={optionsDocumento}
                                            defaultSelectedKeys={editDocumentiIntialValue}
                                            multiSelect
                                            onChange={(event, selectedOption) => {
                                                var temp = [...editDocumentiSelected];
                                                var docList = [];
                                                var fieldLabels = [];
                                                if (temp.filter(t => t.key === selectedOption.key).length <= 0) {
                                                    temp.push(selectedOption)
                                                }
                                                else {
                                                    temp = temp.filter(t => t.key !== selectedOption.key);
                                                }
                                                temp.forEach((t) => {
                                                    docList.push(t.key);
                                                    fieldLabels.push(t.text);
                                                })
                                                setEditDocumentiSelected(temp);
                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Documenti", fieldValue: docList.join(), fieldLabel: fieldLabels.join() })
                                            }}
                                        />
                                        <DefaultButton text="Annulla"
                                            style={{ "marginRight": "5px", marginTop: "5px" }}
                                            onClick={() => {
                                                handleShowEditInboundField("Documenti", false)
                                            }}
                                        />
                                        <PrimaryButton
                                            id="btnUpdateInboundDocumenti"
                                            text="Salva"
                                            style={{ minWidth: "100px", marginTop: "5px" }}
                                            onClick={() => {
                                                fetchUpdateInbound(selectedRowDetails);
                                            }}
                                            allowDisabledFocus
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="DetailsRow d-flex">
                                <div className={(showEditMezzo) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                    <div>
                                        <Icon iconName="Transition" /> <b>Mezzo<sup className="text-danger">*</sup> :</b>{" "}
                                        <span id="ValueLabelMezzo">{selectedRowDetails.mezzo}</span>
                                    </div>
                                    {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditMezzo) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditInboundField("Mezzo", true)}>[modifica]</a>}
                                </div>
                                <div className={(showEditMezzo) ? "col-6 d-inline" : "col-6 d-none"}>
                                    <div className={(showEditMezzo) ? "d-inline" : "d-none"}>
                                        <Dropdown
                                            id="EditMezzo"
                                            name="Mezzo"
                                            placeholder="Scegli Mezzo"
                                            options={optionsMezzo}
                                            onChange={(item, selectedOption) =>
                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Mezzo", fieldValue: selectedOption.key, fieldLabel: selectedOption.text })
                                            }
                                        />
                                        <DefaultButton text="Annulla"
                                            style={{ "marginRight": "5px", marginTop: "5px" }}
                                            onClick={() => {
                                                handleShowEditInboundField("Mezzo", false)
                                            }}
                                        />
                                        <PrimaryButton
                                            id="btnUpdateInboundMezzo"
                                            text="Salva"
                                            style={{ minWidth: "100px", marginTop: "5px" }}
                                            onClick={() => {
                                                fetchUpdateInbound(selectedRowDetails).then(() => {
                                                    selectedRowDetails.mezzo = updateData.fieldLabel;
                                                    selectedRowDetails.idMezzo = updateData.fieldValue;
                                                });
                                            }}
                                            allowDisabledFocus
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="DetailsRow d-flex">
                                <div className={(showEditRicezione) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                    <div>
                                        <Icon iconName="Light" /> <b>Ricezione:</b>{" "}
                                        <span id="ValueLabelRicezione">{selectedRowDetails.ricezione}</span>
                                    </div>
                                    {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditRicezione) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditInboundField("Ricezione", true)}>[modifica]</a>}
                                </div>
                                <div className={(showEditRicezione) ? "col-6 d-inline" : "col-6 d-none"}>
                                    <div className={(showEditRicezione) ? "d-inline" : "d-none"}>
                                        <Dropdown
                                            id="EditRicezione"
                                            name="Ricezione"
                                            placeholder="Scegli Ricezione"
                                            options={optionsRicezione}
                                            onChange={(item, selectedOption) => {
                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Ricezione", fieldValue: selectedOption.key, fieldLabel: selectedOption.text })
                                            }}
                                        />
                                        <DefaultButton text="Annulla"
                                            style={{ "marginRight": "5px", marginTop: "5px" }}
                                            onClick={() => {
                                                handleShowEditInboundField("Ricezione", false)
                                            }}
                                        />
                                        <PrimaryButton
                                            id="btnUpdateInboundRicezione"
                                            text="Salva"
                                            style={{ minWidth: "100px", marginTop: "5px" }}
                                            onClick={() => {
                                                fetchUpdateInbound(selectedRowDetails).then(() => {
                                                    selectedRowDetails.ricezione = updateData.fieldLabel;
                                                    selectedRowDetails.idRicezione = updateData.fieldValue;
                                                });
                                            }}
                                            allowDisabledFocus
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="DetailsRow d-flex">
                                <div className={(showEditIdTicket) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                    <div>
                                        <Icon iconName="Ticket" /> <b>ID Ticket:</b>{" "}
                                        <span id="ValueLabelIdTicket">{selectedRowDetails.idTicket}</span>
                                    </div>
                                    {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditIdTicket) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditInboundField("IdTicket", true)}>[modifica]</a>}
                                </div>
                                <div className={(showEditIdTicket) ? "col-6 d-inline" : "col-6 d-none"}>
                                    <div className={(showEditIdTicket) ? "d-inline" : "d-none"}>
                                        <TextField
                                            id="IdTicket"
                                            defaultValue={selectedRowDetails.idTicket}
                                            onChange={(event, value) => {
                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "IdTicket", fieldValue: value, fieldLabel: value })
                                            }}
                                        />
                                        <DefaultButton text="Annulla"
                                            style={{ "marginRight": "5px", marginTop: "5px" }}
                                            onClick={() => {
                                                handleShowEditInboundField("IdTicket", false)
                                            }}
                                        />
                                        <PrimaryButton
                                            id="btnUpdateInboundIdTicket"
                                            text="Salva"
                                            style={{ minWidth: "100px", marginTop: "5px" }}
                                            onClick={() => {
                                                fetchUpdateInbound(selectedRowDetails).then(() => {
                                                    selectedRowDetails.idTicket = updateData.fieldLabel;
                                                });
                                            }}
                                            allowDisabledFocus
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="DetailsRow d-flex">
                                <div className={(showEditNote) ? "d-flex justify-content-between col-6" : "d-flex justify-content-between col-12"}>
                                    <div>
                                        <Icon iconName="DietPlanNotebook" /> <b>Note:</b>{" "}
                                        <span id="ValueLabelNote">{selectedRowDetails.note}</span>
                                    </div>
                                    {(protocolloState.roleIsReadOnly) ? null : <a href="javascrip:void(0)" className={(!showEditNote) ? "ml-2 d-inline" : "ml-2 d-none"} onClick={() => handleShowEditInboundField("Note", true)}>[modifica]</a>}
                                </div>
                                <div className={(showEditNote) ? "col-6 d-inline" : "col-6 d-none"}>
                                    <div className={(showEditNote) ? "d-inline" : "d-none"}>
                                        <TextField
                                            id="Note"
                                            multiline={true}
                                            defaultValue={selectedRowDetails.note}
                                            onChange={(event, value) => {
                                                setUpdateData({ idProtocollo: selectedRowDetails.idProtocollo, fieldName: "Note", fieldValue: value, fieldLabel: value })
                                            }}
                                        />
                                        <DefaultButton text="Annulla"
                                            style={{ "marginRight": "5px", marginTop: "5px" }}
                                            onClick={() => {
                                                handleShowEditInboundField("Note", false)
                                            }}
                                        />
                                        <PrimaryButton
                                            id="btnUpdateInboundNote"
                                            text="Salva"
                                            style={{ minWidth: "100px", marginTop: "5px" }}
                                            onClick={() => {
                                                fetchUpdateInbound(selectedRowDetails).then(() => { selectedRowDetails.note = updateData.fieldLabel; });
                                            }}
                                            allowDisabledFocus
                                        />
                                    </div>
                                </div>
                            </div>

                            {(!protocolloState.roleIsReadOnly) ?
                                <div className="DetailsRow">
                                    <div className="col-12 text-center">
                                        <PrimaryButton
                                            className={(showConfirmDelete) ? "d-none" : "d-inline"}
                                            id="btnDeleteInbound"
                                            text="Elimina questo protocollo"
                                            style={{ minWidth: "100px", marginTop: "5px", backgroundColor: 'red', borderColor: 'red' }}
                                            onClick={() => {
                                                setShowConfirmDelete(true);
                                            }}
                                            allowDisabledFocus
                                        />
                                        <div id="DeleteConfirmation" className={(showConfirmDelete) ? "d-inline" : "d-none"}>
                                            <h6>Sei sicuro di voler eliminare questo protocollo?</h6>
                                            <DefaultButton
                                                id="btnConfirmDeleteInbound"
                                                text="Annulla eliminazione"
                                                style={{ minWidth: "100px", marginTop: "5px", marginRight: "5px" }}
                                                onClick={() => {
                                                    setShowConfirmDelete(false);
                                                    setErrorUpdatingMessage("");
                                                }}
                                                allowDisabledFocus={false}
                                                disabled={((isDoingDelete) ? true : false)}
                                            />
                                            <PrimaryButton
                                                id="btnCancelDeleteInbound"
                                                text="Conferma eliminazione"
                                                style={{ minWidth: "100px", marginTop: "5px", backgroundColor: 'red', borderColor: 'red' }}
                                                onClick={() => {
                                                    setErrorUpdatingMessage("");
                                                    deleteProtocollo(selectedRowDetails.idProtocollo).then((res) => {
                                                        if (res === true) {
                                                            setIsModalOpen(false);
                                                            setShowConfirmDelete(false);
                                                        }
                                                        else if (res === false) {

                                                        }
                                                        setIsDoingDelete(false);
                                                    });
                                                }}
                                                allowDisabledFocus={false}
                                                disabled={((isDoingDelete) ? true : false)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                : null
                            }

                            <div className={(errorUpdatingMessage != "") ? "DetailsRow d-inline" : "DetailsRow d-none"}>
                                <div className="alert alert-danger" role="alert" id="errorUpdatingMessage">
                                    {errorUpdatingMessage}
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Stack>
            </Fabric>
        </div>
}
export default Inbound;