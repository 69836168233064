import React from 'react';
const Loading = (props) => {
    var message = (props.message !== "" && props.message != null && props.message !== undefined) ? props.message : "Loading...";
    return (
        <div className="text-center ">
            <div className="spinner-border mt-5 text-center" role="status">
                <span className="sr-only">{message}</span>
            </div>
            <div className="mt-3">{message}</div>
        </div>
    )
}
export default Loading;